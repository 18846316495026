<template>
  <div class="selector-wrapper">
    <div class="hide-content"></div>
    <div class="selector-close" @click="onClose">
      <img src="/icons/marketplace/close.svg" />
    </div>
    <div class="selector-content">
      <div class="selector-search-block">
        <img class="search-icon" src="/icons/marketplace/search.svg" />
        <input type="text" class="search-block-input" v-model="search" placeholder="Пошук міста" />
      </div>
      <div class="selector-list">
        <div class="selector-city" @click="onGeoSelect">
            <img class="geo-icon"  src="/icons/marketplace/location-black.svg">
            На основі мого розташування
        </div>
        <div 
            class="selector-city" 
            v-for="(city,index) in filteredCities" :key="index"
            @click="onSelect(city) ">
            {{ city }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cities: Array
  },
  data() {
    return {
      search: null
    };
  },
  methods: {
      onClose() {
          this.$emit('close');
      },
      onSelect(city) {
          this.$emit('select', city);
      },
      onGeoSelect() {
          this.$emit('geo');
      }
  },
  computed: {
    filteredCities() {
      if (!this.search) return this.cities;

      return this.cities.filter(c => {
        return c.toLowerCase().includes(this.search.toLowerCase());
      });
    }
  },
  mounted() {
    this.toggleGlobalScrolling(true);
  },
  beforeDestroy() {
    this.toggleGlobalScrolling(false);
  }
};
</script>

<style scoped>


.hide-content {
  width: 100vw;
  height: 100vh;

  z-index: 5;
  overflow: hidden;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.9);
  opacity: 1;

  position: fixed;

  top: 0;
  left: 0;
}

.selector-content {
  width: 100vw;
  height: 90vh;

  z-index: 10;
  overflow: hidden;
  position: fixed;

  top: 0;
  left: 0;

  margin-top: 9vh;
}

.selector-search-block {
  text-align: center;
  display: flex;
  position: relative;
  border-radius: 5vh;
  border: 1px solid #ededed;
  padding: 1.4vh 3vh;
  width: 72.5%;
  margin: 0 auto;
  margin-top: 1.3vh;
}

.selector-list {
  overflow: auto;
  height: 80vh;
  margin-top: 2.2vh;
}

.search-block-input {
  width: 100%;
  background: transparent;
  display: block;
  font-size: 3vh;
  line-height: initial;
  border: none;
  color: white;
  outline: none;
}

.selector-city {
  padding: 1.6vh 0;
  font-weight: bold;
  font-size: 3vh;
  width: 72.5%;
  line-height: initial;
  margin: 2.2vh auto 0;
  background: #edededed;
  border-radius: 5vh;
  box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.25);
  font-weight: 400;
  color: black;
  text-align: center;
}

.selector-city:first-of-type {
  margin-top: 0;
}

.selector-close {
  position: fixed;
  right:2.2vh;
  top: 2.2vh;
  z-index: 15;
  width: 5vh;
  height: 5vh;

  cursor: pointer;
}

.selector-close img {
  height: 100%;
  width: 100%;
}

.search-icon {
  margin: 0.3vh 2.5vh 0 0;
  height: 3vh;
  width: 3vh;

}


.geo-icon {
  vertical-align: baseline;
  height: 2.5vh;
}

@media screen and (max-width: 450px) {
  .selector-search-block, .selector-city, .search-block-input {
    width: 90%;
    font-size: 2.3vh;
  }
  .selector-search-block {
    padding: 1.2vh 2vh;
  }
  .selector-content {
    margin-top: 8vh;
  }
  .search-icon {
    margin-right: 1.7vh;
  }
}
</style>