<template>
  <div>
   
    <div class="cities-list" :style="{
          top:isBannerShown ? '13.5vh':'7.7vh'
        }" v-thematic>
      <ul v-thematic>
        <li :class="current_city == 'geo' ? 'active' : ''" @click="onGeoSelect" v-thematic>
          {{ $t('marketplace.city_filters.geo') }}
        </li>
        <!-- <li :class="!current_city ? 'active' : ''" @click="onCitySelect(null)" v-thematic>
          {{ $t('marketplace.city_filters.all') }}
        </li> -->
        <li 
          v-for="city in filteredCities" 
          @click="onCitySelect(city)" 
          :key="city" 
          :class="{
            active: current_city == city
          }"
          v-thematic>{{ city }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    all_cities: Array,
    city_search: String,
    current_city: String
  },
  data() {
    return {
      selected_city: null,
      searchActive: false,
      search: null
    };
  },
  methods: {
    onGeoSelect() {
      this.$emit("geo");
    },
    onCitySelect(city) {
      this.$emit("city", city);
    }
  },
  computed: {
    isBannerShown() {
      return this.$store.state.uiBannerShown;
    },
    filteredCities() {
      if (!this.city_search) return this.all_cities;

      return this.all_cities.filter(city => {
        return city.toLowerCase().includes(this.city_search.toLowerCase());
      });
    }
  }
};
</script>

<style scoped>
.cities-list {
  /* height: 66vh; */
  height: 100vh;
  background: #ededed;
  position: fixed;
  overflow: auto;
  top: 7.5vh;
  color: #1d1d1d;
  z-index: 1;
  width: 99%;
  padding: 0;
  left: 0;

  padding-bottom: 12vh;
}

.cities-list.dark {
  background: black;
}

.cities-list li.dark {
  color: #908f8d;
  border: 1px solid #908f8d;
}

.cities-list ul {
  text-align: center;
  padding: 0 2.5vh 12vh;
  min-height: 80vh;
  list-style: none;
  margin: 0;
}
.cities-list li {
  padding: 1.5vh 0;
  background: transparent;
  border: 1px solid #1d1d1d;
  border-radius: 5vh;
  margin-top: 2.5vh;
  font-size: 2vh;
  color: #464646;
}

.cities-list li.active {
  color: #ededed;
  border: 1px solid transparent;
  background: #129b81;
}

.filter-header,
.cities-header {
  display: flex;
  width: 100%;
  align-items: center;
}
 

img.filter {
  height: 2.4vh;
  margin-right: 2vh;
  width: auto;
}

img.close {
  height: 2vh;
  margin: 0 2vh;
  opacity: 1;
  width: auto;
}   
</style>