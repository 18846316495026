<template>
  <div
    :style="
      `${
        right ? 'right:' + retreat + 'vh;' : 'left:' + retreat + 'vh;'
      }width:${width}vh`
    "
    :class="{ 'scroll-button-desktop': true, right: right, left: !right }"
    @click="onClicked"
    v-if="canShow"
  >
    <svg
      width="9"
      height="26"
      viewBox="0 0 9 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.48749 25.3541C8.9555 25.0669 9.10396 24.4517 8.81908 23.9799L2.18872 13L8.81908 2.02007C9.10396 1.54832 8.9555 0.933102 8.48749 0.645945C8.01948 0.358789 7.40915 0.508438 7.12427 0.980192L0.179937 12.4801C-0.0129198 12.7994 -0.0129198 13.2006 0.179937 13.5199L7.12427 25.0198C7.40915 25.4916 8.01948 25.6412 8.48749 25.3541Z"
      />
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    right: {
      type: Boolean,
      default: false,
    },
    retreat: {
      type: String,
      default: "2.7777777777777777",
    },
    width: {
      type: String,
      default: "7.222222222222222",
    },
    count: Number,
    elementId: String,
    changeNumber: Number
  },
  data() {
    return {
      canShow: true,

      el: null
    }
  },
  watch:{ 
    changeNumber() {
      this.check();
    }
  },
  methods: {
    init() {
      this.el = document.getElementById(this.elementId);

      this.check();

      if (this.el) this.el.addEventListener('scroll', this.check.bind(this));
    },

    getItemWidth() {
      return Math.round(this.el.scrollWidth / this.count);
    },

    check() {
      if (!this.el) return;

      const scroll_left = this.el.scrollLeft;

      if (this.right) {
        console.log(scroll_left, this.el.scrollWidth, this.getItemWidth(), this.el.getBoundingClientRect().width);
        this.canShow = scroll_left <= this.el.scrollWidth - this.getItemWidth() - this.el.getBoundingClientRect().width;
      } else {
        this.canShow = scroll_left > 10;
      }
    },
    onClicked() {
      if (this.el) {
        if (this.right) {
          this.el.scrollLeft += this.getItemWidth();
        } else {
          this.el.scrollLeft -= this.getItemWidth();
        }
      }

      this.check();
    }
  },
  beforeDestroy() {
    if (this.el) this.el.removeEventListener('scroll', this.check.bind(this));
  },
  mounted() {
    setTimeout(() => {
      this.init();
    }, 650);
  }
};
</script>
<style scoped>
.scroll-button-desktop {
  height: 100%;
  background: #ededed;
  border-radius: 1.11111vh;
  backdrop-filter: blur(20px);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.scroll-button-desktop:hover {
  background: #dbdbdb;
}
.scroll-button-desktop:hover svg path {
  fill: #1d1d1b;
}
.right {
  transform: rotate(180deg);
  position: absolute;
}
.left {
  position: absolute;
}
svg {
  height: 2.7777777777777777vh;
}
svg path {
  fill: #908f8d;
}
</style>
