<template>
  <div>
    <transition :name="`slide-${slide_way}`"
      ><div v-if="!city_selector" class="cafe-filters-container">
        <div class="cafe-filters">
          <div class="filter-column">
            <div class="filter-column-title">
              {{ $t('marketplace.filters.desktop_title') }}
            </div>
            <div class="city-selector" :class="{
              'active-city': !!filters.city
            }" @click="city_selector = true">
              {{ filters.city ? filters.city : $t("marketplace.filters.geo_city") }}
              <img src="/icons/desktop/arrow.svg" v-if="!filters.city" />
              <img src="/icons/desktop/arrow-white.svg" v-else />
            </div>
            <div class="slider-header" v-if="!filters.city">
              <span>{{ filters.range }} km</span>
              <!-- <span>165 закладів</span> -->
            </div>
            <div v-if="!filters.city">
              <SlideBar :min="5" :max="100" v-model.number="filters.range" />
            </div>
          </div>
          <div class="filter-column">
            <div class="filter-column-title">
              {{ $t("marketplace.filters.by_mode") }}
            </div>
            <div
              @click="onModeSelect(system.name)"
              v-for="(system, i) in systems"
              :key="i"
              class="system-row"
            >
              <div>
                <div
                  :class="{
                    'system-name': true,
                    'black-text': system.name == filters.mode,
                  }"
                >
                  {{ system.name.toUpperCase() }}
                </div>
                <div class="system-description">{{ system.description }}</div>
              </div>
              <div
                :class="{
                  'system-selected-indicator': true,
                  selected: system.name == filters.mode,
                }"
              ></div>
            </div>
          </div>
          <div class="filter-column">
            <div class="filter-column-title">
              {{ $t("marketplace.filters.by_price") }}
            </div>
            <div
              @click="onPriceSelect(price)"
              v-for="(price, i) in prices"
              :key="i"
              class="system-row"
            >
              <div>
                <div
                  :class="{
                    'system-name': true,
                    'black-text': price == filters.price_level,
                  }"
                >
                  {{ price }}
                </div>
              </div>
              <div
                :class="{
                  'system-selected-indicator': true,
                  selected: price == filters.price_level,
                }"
              ></div>
            </div>
          </div>
        </div>
        <div class="devider"></div>
        <div class="apply-button" :class="{
          'active-btn': isFilterActive
        }" @click="applyFilters">
          {{ $t("marketplace.filters.apply") }}
        </div>
      </div>
    </transition>
    <transition :name="`slide-${slide_way}`"
      ><div v-if="city_selector" class="cafe-filters-container">
        <div class="city-selector-container">
          <div
            class="city-selector-item"
            :class="{
              'active-city': !filters.city,
            }"
            @click="filters.city = null"
          >
            {{ $t("marketplace.filters.geo_city") }}
          </div>
          <div
            class="city-selector-item"
            :class="{
              'active-city': filters.city == city,
            }"
            v-for="city in cities"
            :key="city"
            @click="filters.city = city"
          >
            {{ city }}
          </div>
        </div>
        <div class="devider"></div>
        <div class="apply-button" :class="{
          'active-btn': isFilterActive
        }" @click="city_selector = false">
          {{ $t("marketplace.filters.apply") }}
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import SlideBar from "./SlideBar.vue";

export default {
  data() {
    return {
      city_selector: false,
      slide_way: "left",
      selected_system: null,
      prices: ["$", "$$", "$$$", "$$$$"],
      selected_price: null,

      slider_value: null,

      filters: {
        range: 10,
        mode: null,
        price_level: null,
        city: null,
      },
    };
  },
  props: {
    initial_filters: Object,
    cities: Array,
  },
  watch: {
    city_selector() {
      setTimeout(() => {
        if (this.city_selector) {
          this.slide_way = "right";
        } else this.slide_way = "left";
      }, 1000);
    },
  },
  computed: {
    isFilterActive() {
      return this.filters.city || this.filters.price_level || this.filters.mode;
    },
    systems() {
      return [
        { name: "inside", description: this.$t('marketplace.filters.sys_inside') },
        { name: "preorder", description:  this.$t('marketplace.filters.sys_preorder') },
        { name: "delivery", description:  this.$t('marketplace.filters.sys_delivery') },
        { name: "reserve", description:  this.$t('marketplace.filters.sys_reserve')},
      ]
    }
  },
  methods: {
    onSlide(value) {
      console.log(value);
      this.slider_value = +value;
    },
    applyFilters() {
      this.$emit("apply", this.filters);
    },
    selectCity(c) {
      this.filters.city = c;
      this.city_selector = false;
    },
    showCitiesUI() {
      this.city_selector = true;
    },
    onModeSelect(sys) {
      const value = this.filters.mode == sys ? null : sys;
      this.$set(this.filters, 'mode', value);
    },
    onPriceSelect(lvl) {
      const value = this.filters.price_level == lvl ? null : lvl;
      this.$set(this.filters, 'price_level', value);
    }
  },
  components: { SlideBar },
  mounted() {
    if (this.initial_filters) {
      this.filters = Object.assign({}, this.initial_filters);
    }
  },
};
</script>
<style scoped>
.cafe-filters-container {
  width: 100vw;
  position: absolute;
  left: 0;
  z-index: 3;
  top: 6.222222222222222vh;
  background: rgba(237, 237, 237, 0.9);
  backdrop-filter: blur(20px);
  border-radius: 0px 0px 2.222vh 2.222vh;
}
.cafe-filters {
  padding: 3.3333vh 16vh;
  display: flex;
  justify-content: space-between;
  margin-bottom: 3.3333vh;
}
.filter-column {
  width: calc(33.333% - 3.6666666666666665vh);
}
.filter-column-title {
  font-size: 1.5555555555555556vh;
  line-height: 1.7777777777777777vh;
  text-transform: uppercase;
  color: #464646;
  padding: 1.3333333333333333vh 0;
  border-bottom: 0.1111vh solid #464646;
}
.system-row {
  width: 100%;
  margin-top: 2.2222vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.system-name {
  font-weight: normal;
  font-size: 2vh;
  line-height: 132%;
  color: #908f8d;
}
.system-description {
  font-weight: normal;
  font-size: 1.4444444444444444vh;
  line-height: 132%;
  color: #908f8d;
}
.system-selected-indicator {
  width: 2.4444444444444446vh;
  height: 2.4444444444444446vh;
  border-radius: 50%;
  border: 0.1111vh solid #908f8d;
}
.selected {
  border: 0.1111vh solid #129b81;
  background: #129b81;
}
.black-text {
  color: #1d1d1b;
}
.devider {
  border-top: 0.1111vh solid #908f8d;
  width: 76.81%;
  margin: 0 auto 3.3333vh;
}
.city-selector {
  width: 100%;
  height: 5.555555555555555vh;
  background: #ffffff;
  border-radius: 1.3333333333333333vh;
  margin-top: 2.222vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2.2222vh;
  font-size: 2vh;
  line-height: 132%;
  color: #1d1d1b;
  cursor: pointer;
}
.city-selector img {
  width: 1.6666666666666667vh;
  margin-left: 2.2222vh;
}
.slider-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 2.222vh;
  line-height: 2.5555555555555554vh;
  color: #908f8d;
  margin-top: 2.22222vh;
  margin-bottom: 1.1111vh;
}
.apply-button {
  width: 37.22222222222222vh;
  height: 5.555555555555555vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #129b81;
  border-radius: 1.1111vh;
  margin: 0 auto 3.3333333333333335vh;
  font-size: 1.7777777777777777vh;
  line-height: 2vh;
  color: #ededed;
  cursor: pointer;
  opacity: 0.7;
}
.active-btn {
  opacity: 1;
}
.city-selector-container {
  display: flex;
  flex-wrap: wrap;
  padding: 2.4444444444444446vh 18.555555555555557vh 3.3333vh;
  overflow: scroll;
  max-height: 81.4vh;
}
.city-selector-container::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.city-selector-item {
  cursor: pointer;
  width: 37.22222222222222vh;
  height: 5.555555555555555vh;
  border: 0.1111vh solid #1d1d1b;
  box-sizing: border-box;
  border-radius: 2.7777777777777777vh;
  font-size: 1.7777777777777777vh;
  line-height: 2vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1d1d1b;
  margin-right: 5.555555555555555vh;
  margin-top: 2.2222vh;
}
.active-city {
  background: #129b81;
  color: #ededed;
  border-color: #129b81;
}
.city-selector-item:nth-child(3n) {
  margin-right: 0;
}
.slide-left-leave-active,
.slide-left-enter-active {
  transition: 1s;
}
.slide-left-enter {
  transform: translate(100%, 0);
}
.slide-left-leave-to {
  transform: translate(-100%, 0);
}

.slide-right-leave-active,
.slide-right-enter-active {
  transition: 1s;
}
.slide-right-enter {
  transform: translate(-100%, 0);
}
.slide-right-leave-to {
  transform: translate(100%, 0);
}
</style>
