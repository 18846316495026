<template>
  <div>
    <NavbarDesktop
      variant="marketplace"
      @search="(v) => (search = v)"
      @openMap="onNavbarMapClicked"
      @openFilters="show_filters = !show_filters; map = false"
      @statusClicked="show_web_status = !show_web_status"
      :are_filters_active="
        show_filters
      "
      :is_map_active="map"
      :city="current_filter.city || detected_city"
      :cityFilter="!!current_filter.city"
      :statusPresent="has_web_status"
      :showBackButton="!!viewed_chain_cafes"
      :statusTimer="status_timer"
      @back="viewed_chain_cafes = null"
    />
    <div v-if="!map" class="marketplace-list-desktop">
      <div class="category-list-container">
        <ScrollButtonDesktop
          :elementId="'marketplaceCategories'"
          :count="nonEmptyFoodFlavors.length"
          retreat="0"
          width="4.8"
          class="height-center"
        />
        <ScrollButtonDesktop
          :elementId="'marketplaceCategories'"
          :count="nonEmptyFoodFlavors.length"
          retreat="0"
          width="4.8"
          class="height-center"
          :right="true"
        />
        <div class="category-list" id="marketplaceCategories">
          <div class="controll-arrow left"></div>
          <div
            class="category-item"
            @click="selected_flavor_id = null"
            :class="{
              selected: !selected_flavor_id,
            }"
          >
            <div class="category-item-icon-container">
              <img :src="
                selected_flavor_id ? '/icons/marketplace/flavor-all-desktop.png': '/icons/marketplace/flavor-all-desktop-active.png'
              ">
            </div>
            <div class="category-name">
              {{ $t('marketplace.list.all_categories') }}
            </div>
          </div>
          <div
            v-for="flavor in nonEmptyFoodFlavors"
            :key="flavor._id"
            class="category-item"
            @click="onFlavorSelected(flavor)"
            :class="{
              selected: selected_flavor_id == flavor._id,
            }"
          >
            <div class="category-item-icon-container">
              <img :src="selected_flavor_id == flavor._id ? flavor.image_new_desktop_active || flavor.image_desktop_active : flavor.image_new_desktop || flavor.image_desktop" alt="" />
            </div>
            <div class="category-name">
              {{ getFlavorDisplayName(flavor) }}
            </div>
          </div>
        </div>
      </div>

      <div class="list-container" v-if="!busy">
        <div
          v-if="!filteredList || !filteredList.length"
          class="no-cafes-container"
        >
          <img src="../../assets/error-hero.svg" />
          <h4>{{ $t("marketplace.list.no_cafes") }}</h4>
        </div>

        <MarketplaceCafeCardDesktop
          :cafe="cafe"
          v-for="cafe in filteredList"
          :key="cafe._id"
          @chainSelected="v => viewed_chain_cafes = v"
        />
      </div>
      <div class="list-container loading" v-else>
        <img src="/hero-pics/eat.svg" class="loading-panda" />
        <h3>
          {{ $t("marketplace.list.loading") }}
        </h3>
      </div>
    </div>
    <MarketplaceMap
      :cafes="list"
      :startPos="current_geo_position"
      @close="map = false"
      ref="marketplaceMap"
      v-else
    />
    <CafeFiltersDesktop
      ref="marketplaceFilters"
      :cities="cities"
      @apply="onApplyFilters"
      :initial_filters="current_filter"
      v-if="show_filters"
    />

    <OrderStatusNotification
      @show="has_web_status = true"
      v-show="show_web_status"
      @timer="v => status_timer = v"
      @close="show_web_status = false"
    />

    <InfoPopupDesktop 
      variant="geo"
      @geoConfirmed="onCityGeoSelect"
      @geoDenied="onGeoDenied"
      v-if="geo_modal "/>
  </div>
</template>
<script>
import GeolocationService from "@/services/geolocation";
import MarketplaceCafeCardDesktop from "@/components/desktop/MarketplaceCafeCardDesktop.vue";
import MarketplaceAPI from "@/api/marketplace";
import ScrollButtonDesktop from "./ScrollButtonDesktop.vue";
import NavbarDesktop from "@/components/desktop/NavbarDesktop.vue";
import MarketplaceMap from "@/components/marketplace/MarketplaceMap.vue";
import ScheduleService from "@/services/schedule";
import CafeFiltersDesktop from "@/components/desktop/CafeFiltersDesktop.vue";
import OrderStatusNotification from "@/components/OrderStatusNotification.vue";
import InfoPopupDesktop from "@/components/desktop/InfoPopupDesktop.vue"

///
export default {
  components: {
    MarketplaceCafeCardDesktop,
    ScrollButtonDesktop,
    NavbarDesktop,
    MarketplaceMap,
    CafeFiltersDesktop,
    OrderStatusNotification,
    InfoPopupDesktop
  },
  data() {
    return {
      list: [],
      busy: true,
      map: false,
      current_geo_position: null,
      food_flavors: [],
      selected_flavor_id: null,
      search: null,

      show_web_status: false,
      has_web_status: false,

      show_filters: false,
      current_filter: {
        range: 5,
        city: null,
        modes: null,
        price_levels: null,
      },

      cities: [],
      flavor_cities: [],
      geo_modal: false,
      detected_city: null,
      status_timer: null,

      viewed_chain_cafes: null
    };
  },
  created() {
    this.fetchCities();
    // this.fetchList();
    // this.onCityGeoSelect();
    this.fetchCategories();
    this.$nextTick(this.checkCachedPermission);
  },
  methods: {
    onFlavorSelected(flavor) {
      if (this.selected_flavor_id == flavor._id) {
        this.selected_flavor_id = null;
      } else {
        this.selected_flavor_id = flavor._id;
      }
    },
    fetchCategories() {
      MarketplaceAPI.getFlavors()
        .then((data) => {
          this.food_flavors = data.flavors;
        })
        .catch((error) => {});
    },

    fetchCities() {
      MarketplaceAPI.getCities()
        .then((data) => {
          this.cities = data.cities;
          this.flavor_cities = data.flavor_cities;

          const cached_city = window.localStorage.getItem(
            "marketplace_city_cache"
          );

          if (cached_city && this.cities.includes(cached_city)) {
            this.selected_city = cached_city;
            // this.geo_city_cache = cached_city;
          }
        })
        .catch((error) => {
          this.error = error;
        });
    },
    onNavbarMapClicked() {
      if (!this.map) {
        this.map = true;
        this.show_filters = false;
      } else {
        this.$refs.marketplaceMap.runUiClose();
      }
    },
    fetchList() {
      this.error = null;
      // if (!this.selected_city) {
      //   this.busy = false;
      //   return;
      // }
      if (!this.search) this.busy = true;

      // let target_city = this.selected_city;

      // if (this.current_filter && this.current_filter.city == 'geo') {
      //   target_city = this.geo_city_cache;
      // } else if (this.current_filter && this.current_filter.city) {
      //   target_city = this.current_filter.city;
      // } else {
      //   target_city = undefined;
      // }

      MarketplaceAPI.getList({
        pos: this.current_geo_position || {},
        city: this.current_filter.city || undefined,
        modes: this.current_filter ? this.current_filter.mode : null,
        price_levels: this.current_filter
          ? this.current_filter.price_level
          : null,
        search: this.search,
        range: this.current_filter ? this.current_filter.range : null,
      })
        .then((data) => {
          this.busy = false;
          this.list = data.list;

          this.processSchedules();
          
        })
        .catch((error) => {
          this.error = error;
          this.busy = false;
        });
    },
    onCityGeoSelect() {
      GeolocationService.getPosition()
        .then((pos) => {
          this.current_geo_position = pos;
          this.geo_modal = false;
          window.localStorage.setItem(
            `last_marketplace_geo`,
            JSON.stringify({
              pos,
              timestamp: new Date(),
            })
          );
          window.localStorage.setItem("marketplace_geo_granted", Date.now());
          this.city_selector_modal = false;
          this.fetchList();
          this.detectCity(pos);
        })
        .catch((error) => {});
    },
    getFlavorDisplayName(flavor) {
      if (!flavor.i18n) return flavor.name;
      const translation = flavor.i18n[this.$i18n.locale];

      return translation || flavor.name;
    },
     detectCity(pos) {
      if (this.detected_city) {
        // this.selected_city = this.geo_city_cache;
        return;
      }

      const geocoder = new window.google.maps.Geocoder();

      // const cached_city = window.localStorage.getItem("marketplace_city_cache");

      // if (cached_city) {
      //   return;
      // }

      geocoder.geocode(
        {
          location: {
            lat: pos.lat,
            lng: pos.lon,
          },
        },
        (results, status) => {
          if (status === "OK") {
            const first_result = results[0];

            const components = first_result.address_components;

            const political = components.find((c) =>
              c.types.includes("political")
            );

            if (!political) return;

            const city = political.long_name;

            console.log(`Geocoded city: ${city}`);

            this.detected_city = city;

            // this.selected_city = city;
          }
        }
      );
    },
    processSchedules() {
      this.list.forEach((cafe, index) => {
        if (cafe.entry_type != "single") {
          this.$set(this.list[index], "schedule_verdict", {
            open: true,
            text: null,
          });
          return;
        }

        const helper = new ScheduleService.ScheduleHelper(cafe.schedule);

        const schedule_verdict = {
          open: helper.isOpen(),
          text: helper.format(),
          workday: helper.isWorkday(),
          time_left: helper.remainingTimeHours(),
          open_hours: helper.hoursTillOpen(),
          is_24hr: cafe.schedule_status.is_24hr
        };

        this.$set(this.list[index], "schedule_verdict", schedule_verdict);
      });
    },
    onApplyFilters(filters) {
      this.current_filter = filters;
      this.show_filters = false;
      if (!this.current_geo_position || !this.detected_city) {
        this.onCityGeoSelect();
      }
      this.fetchList();
    },
    checkCachedPermission() {
      const cached_city = window.localStorage.getItem("marketplace_city_cache");

      if (cached_city) {
        this.current_filter.city = cached_city;
        this.fetchList();
        return;
      }

      const permission = window.localStorage.getItem("marketplace_geo_granted");

      if (!permission) {
        this.geo_modal = true;
      } else {
        const diff = Date.now() - +permission;

        if (diff > 1 * 24 * 60 * 60 * 1000) {
          window.localStorage.removeItem("marketplace_geo_granted");
          this.geo_modal = true;
          return;
        }

        this.onCityGeoSelect();
      }
    },
    onGeoDenied() {
      this.geo_modal = false;
      this.openCitiesList();
      window.localStorage.setItem("marketplace_geo2", "user_denied");
    },
    openCitiesList() {
      this.show_filters = true;
      this.$nextTick(() => {
        this.$refs.marketplaceFilters.showCitiesUI();
      });
    }
  },
  computed: {
    filteredList() {
      const list = this.viewed_chain_cafes || this.list;
      return list.filter((cafe) => {
        if (
          this.selected_flavor_id && cafe.food_flavors && 
          !cafe.food_flavors.includes(this.selected_flavor_id)
        )
          return false;

        if (this.search) {
          const q = this.search.toLowerCase();

          return `${cafe.name}${cafe.description}${cafe.city}${cafe.address}`
            .toLowerCase()
            .includes(q);
        }

        return true;
      });
    },
    nonEmptyFoodFlavors() {
      // if (!this.isCityEnabled) return [];

      return this.food_flavors.filter((flavor) => {
        return !!this.list.find(
          (c) => c.food_flavors && c.food_flavors.includes(flavor._id)
        );
      });
    },
  },
};
</script>
<style scoped>
.marketplace-list-desktop {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 6.222222222222222vh;
}
.category-list-container {
  margin: 4.4444vh 0;
  width: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
}
.list-container {
  width: 100vh;
  padding: 0 6vh 2.5vh;
}
.category-list {
  width: 88vh;
  white-space: nowrap;
  overflow-x: scroll;
}
.category-list::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.category-item {
  margin-right: 2.2222vh;
  display: inline-block;
  cursor: pointer;
}
.category-item:last-child {
  margin-right: 0;
}
.category-item-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7.666666666666667vh;
  height: 7.666666666666667vh;
  margin-bottom: 1.1111vh;
  background: #e5e5e5;
  border-radius: 0.4444444444444444vh;
}

.category-item-icon-container img {
  /* width: 4.888888888888889vh; */
  width: 100%;
  height: 100%;
  /* height: 4.888888888888889vh; */
}
.category-name {
  font-size: 1.3333333333333333vh;
  line-height: 1.5555555555555556vh;
  text-align: center;
  width: 7.666666666666667vh;
  color: #908f8d;
}

.category-item.selected .category-item-icon-container {
  background: #1d1d1b;
}

.category-item.selected .category-name {
  color: #1d1d1b;
}

.loading {
  text-align: center;
}

.loading img {
  width: 20vh;
}

.loading h3 {
  margin-top: 1vh;
  font-size: 4.3vh;
}

.no-cafes-container {
  text-align: center;
}

.height-center {
  height: 7.7vh;
}

</style>
