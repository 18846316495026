<template>
  <div>
     
     
    <nav v-thematic>
      <img
        src="../../assets/qr-logo.svg"
        v-if="!show_filters && !has_web_status"
        class="logo"
      />

      <div v-if="!show_filters && has_web_status" class="webstatus-box">
      <img
        src="/icons/webstatus/accepted.svg"
        @click="show_web_status = !show_web_status"
        
        class="logo"
      />

        <small class="waiting-time" v-if="has_web_status && web_status_time">
          {{ web_status_time }}
        </small>
      </div>

      <div class="b-line" v-if="show_web_status"></div>

      <OrderStatusNotification
        class="order-status-container"
        @show="has_web_status = true"
        @timer="t => web_status_time = t"
        v-show="show_web_status"
      />

      <div class="order-status-backdrop" @click="show_web_status = false" v-if="show_web_status"></div>

      <div class="filter-wrapper" v-if="!show_filters">
        <img
          :src="
            current_filter &&
            (current_filter.mode || current_filter.price_level)
              ? '/icons/marketplace/filter-green.svg'
              : '/icons/marketplace/filter.svg'
          "
          v-if="!show_filters && !searchActive"
          class="filter"
          @click="showFiltersUI"
        />
        <div
          :class="
            searchActive ? 'search-block right active' : 'search-block right'
          "
          v-thematic
          v-if="!show_filters"
        >
          <img src="../../assets/search.svg" alt class="search-icon" />
          <input
            @focus="searchActive = true"
            @blur="searchActive = false"
            type="text"
            :placeholder="searchActive ? $t('marketplace.list.search_ph') : ''"
            v-thematic
            class="search"
            maxlength="128"
            v-model="search"
          />
        </div>
      </div>

      <div class="filter-header" v-if="show_filters && !citiesList">
        <img
          src="/icons/nav-arrow-back.svg"
          class="back"
          @click="show_filters = false"
          v-if="!geo_denied || current_filter.city != 'geo' "
        />
        <div>
          <small>Marketplace</small>
          <h5>{{ $t("marketplace.list.filters") }}</h5>
        </div>
      </div>

      <div class="cities-header" v-if="citiesList">
        <img
          src="/icons/nav-arrow-back.svg"
          class="back"
          @click="hideCitiesList"
        />
        <div v-if="!searchActive">
          <small>{{ $t("marketplace.list.cities_list") }}</small>
          <h5>{{ $t("marketplace.list.filters") }}</h5>
        </div>
      </div>
      <div
        v-if="citiesList"
        :class="
          searchActive ? 'search-block right active' : 'search-block right'
        "
        v-thematic
      >
        <img src="../../assets/search.svg" alt class="search-icon" />
        <input
          type="text"
          :placeholder="searchActive ? $t('marketplace.list.search_ph') : ''"
          @focus="searchActive = true"
          @blur="searchActive = false"
          v-thematic
          class="search"
          maxlength="128"
          v-model="city_search"
        />
      </div>

      <!-- <div class="loc-box">
        <img src="../../assets/location.svg" class="loc" v-if="isGeoCity" @click="showCitySelector"/>
        <span @click="showCitySelector">{{ selected_city }}</span>
      </div>-->
      <button class="go2main" @click="backToModes" v-if="!isMobile">
        На головну
      </button>
    </nav>
    
    <div v-thematic class="content" v-if="!show_filters">
      <div class="error-block" v-if="error">
        <img src="../../assets/error-hero.svg" />
        {{ $t("marketplace.list.load_error") }}
        ({{ error }})
        <LongButton class="try-again" variant="danger" @click="fetchList"
          >Спробувати ще раз</LongButton
        >
      </div>
      <div class="list-loader" v-if="busy && !error">
        <img src="/hero-pics/eat.svg" class="loading-panda" />
        <h3 :class="isDarkMode ? 'color-white' : 'color-black'">
          {{ $t("marketplace.list.loading") }}
        </h3>
      </div>

      <div
        class="wrapper"
        v-thematic
        v-if="
          (!current_filter ||
            (!current_filter.mode && !current_filter.price_level)) &&
          !show_filters
        "
      >
        <div class="location-map-box">
          <small
            class="current-location"
            v-if="selected_city || geo_city_cache"
            @click="openCitiesList"
            >{{ selected_city || geo_city_cache}}</small
          >
          <small
            class="current-location"
            v-if="!selected_city"
            @click="openCitiesList"
          >
          </small>
          <span @click="showing_map = true" class="map-text">{{
            $t("marketplace.list.map")
          }}</span>
        </div>
        <div class="fav-cafes-map" v-thematic>
          <h4 v-thematic>{{ $t("marketplace.list.favorite_cafes") }}</h4>
        </div>
      </div>

      <div class="wrapper" v-thematic v-else-if="current_filter">
        <!-- <small class="current-location" v-if="!current_filter.city">Всі міста</small>
        <small class="current-location" v-else-if="current_filter.city == 'geo'">
          Місто: {{ geo_city_cache}}
        </small>
        <small class="current-location" v-else>
          Місто: {{ current_filter.city }}
        </small> -->
        <small
          class="current-location"
          v-if="selected_city || geo_city_cache"
          @click="openCitiesList"
        >
          {{ $t("marketplace.list.city") }} {{ selected_city  || geo_city_cache}}
        </small>
        <small class="current-location" v-else @click="openCitiesList">
          <!-- {{ $t('marketplace.list.all_cities') }} -->
        </small>

        <div class="fav-cafes-map">
          <h4 class="current-filter" v-thematic>
            {{ current_filter.mode ? current_filter.mode.toUpperCase() : "" }}
            {{ current_filter.mode && current_filter.price_level ? "•" : "" }}
            {{ current_filter.price_level ? current_filter.price_level : "" }}
          </h4>
          <span @click="showing_map = true">
            {{ $t("marketplace.list.map") }}
          </span>
        </div>
      </div>

      <div class="cafe-categories" v-if="!busy">
        <div class="item" @click="selected_food_flavor = null">
          <img :src="
            allFlavorsImage
          ">
          <span :class="{
            'category-title': true,
            'category-active': !selected_food_flavor
          }" v-thematic>
            {{ $t('marketplace.list.all_categories') }}
          </span>
        </div>
        <div
          v-for="(flavor, index) in nonEmptyFoodFlavors"
          :key="index"
          class="item"
          @click="onSelectFlavor(flavor)"
        >
          <img
            :src="
              getFlavorImage(flavor)
            "
          />
          <span
            :class="{
              'category-title': true,
              'category-active': selected_food_flavor == flavor._id,
            }"
            v-thematic
            >{{ getFlavorDisplayName(flavor) }}</span
          >
        </div>

        <div
          class="line"
          v-thematic
          v-if="filteredCafes.length && nonEmptyFoodFlavors.length"
        ></div>
      </div>

      <div class="cards" v-if="!busy && !error">
        <!-- <MarketplaceChainCard /> -->

        <div
          v-if="!filteredCafes || !filteredCafes.length"
          class="no-cafes-container"
        >
          <img src="../../assets/error-hero.svg" />
          <h4>{{ $t("marketplace.list.no_cafes") }}</h4>
        </div>
        <template v-for="(cafe, index) in filteredCafes">
          <MarketplaceChainCard
            :key="`${cafe._id}${index}`"
            :chain="cafe"
            v-if="cafe.entry_type == 'chain'"
          />

          <div
            class="card-box"
            v-else
            :key="`${cafe._id}${index}`"
            @click="navigateToCafe(cafe)"
          >
            <img
              :src="cafe.image ? cafe.image : '/icons/cafe-placeholder.jpg'"
              :alt="`${cafe.name} logo`"
            />
            <div class="labels">
              <div class="label i" v-if="cafe.modes.inside">INSIDE</div>
              <div class="label r" v-if="cafe.modes.reserve">RESERVE</div>
              <div class="label d" v-if="cafe.modes.delivery">DELIVERY</div>
              <div class="label p" v-if="cafe.modes.preorder">TAKEAWAY</div>
            </div>

            <div class="info" v-thematic>
              <div class="main-cafe-wrapper">
                <span class="cafe-name-wrap">
                  {{ cafe.name }}
                </span>

                <div class="sub-info-box">
                  <span class="cafe-pricing">{{
                    cafe.price_level || "$"
                  }}</span>
                  <span class="cafe-rating" v-if="cafe.reviews_rating">
                    {{ cafe.reviews_rating }}
                    <img src="../../assets/hand-white.svg" />
                    <!-- <img src="../../assets/hand-white.svg" v-else /> | -->
                  </span>
                  <span class="discount" v-thematic v-if="cafe.cashback_percent"
                    >{{ cafe.cashback_percent }}%</span
                  >
                </div>

                <div class="worktime-box w-50">
                  
                  <small
                    v-thematic
                    class="info-schedule-closed"
                    v-if="cafe.billing_suspended"
                  >
                    <img src="../../assets/timer-red.svg" class="icon" />
                    {{ $t("marketplace.list.closed") }}
                  </small>
                  <small
                    class="working-time"
                    v-thematic
                    v-if="cafe.schedule_verdict.open && !cafe.billing_suspended"
                  >
                    <img
                      src="../../assets/timer.svg"
                      class="icon"
                      v-if="!isDarkMode"
                    />
                    <img
                      src="../../assets/timer-dark.svg"
                      v-else
                      class="icon"
                    />
                    {{ cafe.schedule_verdict.text }}</small
                  >
                  <small
                    v-thematic
                    class="info-schedule-closed"
                    v-if="
                      !cafe.schedule_verdict.open && !cafe.billing_suspended
                    "
                  >
                    <img
                      src="../../assets/timer-red.svg"
                      class="icon"
                      v-if="!cafe.schedule_verdict.workday"
                    />
                    <span v-if="!cafe.schedule_verdict.workday">{{
                      $t("marketplace.list.closed")
                    }}</span>
                    <span
                      v-thematic
                      v-if="
                        cafe.schedule_verdict.workday && !cafe.billing_suspended
                      "
                    >
                      <img src="../../assets/timer-red.svg" class="icon" />
                      {{ $t("marketplace.list.closed") }}
                    </span>
                  </small>

                  <small
                    class="till-close"
                    v-if="!cafe.schedule_verdict.workday"
                    >{{ $t("marketplace.list.day_off") }}</small
                  >
                  <small
                    class="till-close"
                    v-else-if="
                      cafe.schedule_verdict.open && cafe.schedule_verdict.is_24hr
                    "
                  >
                    {{ $t("marketplace.list.open_24hr") }}
                  </small>
                  <small
                    class="till-close"
                    v-else-if="
                      cafe.schedule_verdict.open && !cafe.billing_suspended
                    "
                  >
                    {{ cafe.schedule_verdict.time_left }}
                    {{ $t("marketplace.list.closes_in") }}
                  </small>
                  <small class="till-close" v-else>
                    {{ cafe.schedule_verdict.open_hours }}
                    {{ $t("marketplace.list.opens_in") }}
                  </small>
                  
                </div>

                <div class="address-distance-box w-50" v-thematic>
                  <div class="location-pic-wrapper">
                    <img
                      src="../../assets/location-dark.svg"
                      v-if="isDarkMode"
                      class="icon"
                    />
                    <img src="../../assets/location.svg" v-else class="icon" />
                    <small class="cafe-address" v-thematic>
                      {{ formatAddress(cafe.address) }}
                    </small>
                  </div>

                  <small class="distance" v-if="cafe.distance"
                    >{{ cafe.distance }}
                    {{ $t("marketplace.list.distance") }}</small
                  >
                </div>
              </div>
            </div>
          </div>
        </template>
        <a href="https://t.me/QRWaiterSupportBot">
          <img
            src="/icons/grey-logo.svg"
            v-if="!isDarkMode"
            class="list-logo"
          />
          <img src="../../assets/logo.svg" v-else class="list-logo" />
        </a>

        <!-- <SupportIcon/> -->
      </div>
       
    </div>

    <MarketplaceFilters
      v-if="show_filters"
      @currentGeo="onCurrentGeo"
      @selectedLocation="selectLocation"
      @close="show_filters = false"
      @apply="onApplyFilters"
      @hideCitiesList="citiesList = false"
      :all_cities="cities"
      :city_search="city_search"
      :initial_filters="current_filter"
      :distances_map="distances_map"
      ref="filtersBlock"
    />

    <MarketplaceCitySelector
      :cities="cities"
      @close="
        city_selector_modal = false;
        toggleGlobalScrolling(false);
      "
      @select="onCitySelect"
      @geo="onCityGeoSelect"
      v-if="city_selector_modal"
    />

    <MarketplaceMap
      :cafes="list"
      :startPos="geo_denied ? null : current_geo_position"
      @cafe="navigateToCafe"
      @close="showing_map = false"
      v-if="showing_map"
    />

    <MarketplaceGeoModal
      @deny="onGeoDeny"
      @confirm="onGeoConfirm"
      v-if="geo_modal"
    />

    <SnakeGame
      :source="'Marketplace'"
      @close="playing_snake = false"
      v-if="playing_snake"
    />
  </div>
</template>

<style scoped>


nav {
  height: 7.7vh;
  z-index: 3;
  align-items: center;
   
  position: fixed;
  width: 100%;
  background: #1d1d1b;
  color: #ededed;
  display: flex;
  padding: 1.8vh 0 1.8vh 2.5vh;
  justify-content: space-between;
}

nav.dark {
  background: #1d1d1d;
}

.b-line {
  height: 1px;
  position: absolute;
  top: 7.7vh;
  left: 0;
  z-index: 10;
  background: #908f8d;
  width: 100%;
}

.webstatus-box {
  display: flex;
  align-items: center;
}

small.waiting-time {
  color: #ededed;
  font-size: 1.7vh;
  padding-left: 1.2vh;
  display: block;

}

.worktime-box,
.address-distance-box {
  padding-top: 1.3vh;
  align-self: end;
}

.worktime-box {
  padding-right: 0.6vh;
}

.address-distance-box {
  padding-left: 0.6vh;
}

small.till-close {
  padding-top:0.6vh;
  color: #908f8d;
}


small.distance {
    /* color: #c1c1c1; */
    color: #908f8d;
  padding-top: 0.6vh;
}

.small.till-close.dark,
small.distance.dark {
  color: #5f5f5f;
}

.working-time.dark {
  color: #c1c1c1;
}

.worktime-box small {
  align-items: center;
}


.location-map-box,
.till-close-distance-box {
  display: flex;
  align-items: baseline;
  width: 100%;
  justify-content: space-between;
}

.worktime-address-box small {
  align-items: center;
  width: 50%;
}

.cafe-address,
.distance {
  margin-right: auto;
}

.cafe-address.dark {
  color: #c1c1c1;
}

.till-close-distance-box {
  width: 100%;
  justify-content: unset;
}

.till-close-distance-box small {
  color: #c1c1c1;
}

.till-close-distance-box.dark small {
  color: #464646;
}

button.go2main {
  /* background: #e2223b; */
  background: #cbd9e6;
  color: #1d1d1b;
  border: none;
  border-radius: 1vh;
  padding: 0.9vh 2vh;
  outline: none;
  cursor: pointer;
  z-index: 5;
  width: 18vh;
  height: 4.8vh;
  font-size: 2vh;
  line-height: 0.2vh;

  z-index: 20;
}

.labels {
  display: flex;
  flex-flow: column;
  position: absolute;
  top: 0.5vh;
  right: -0.5vh;
}

.label {
  font-size: 1vh;
  text-align: center;
  width: 7vh;
  padding: 0.5vh 0;
  line-height: initial;
  color: #1d1d1b;
  letter-spacing: -1px;
  margin-bottom: 0.6vh;
  font-weight: bold;
  border-radius: 0.5vh;
}

.filter-header,
.cities-header {
  display: flex;
  width: 100%;
  align-items: center;
}

.cities-header h5,
.filter-header h5 {
  margin: 0;
  width: max-content;
  font-size: 2.2vh;
}

div small {
  color: #464646;
  padding: 0;
  display: flex;
  font-size: 1.6vh;
  line-height: normal;
}

div small.dark {
  color: #908f8d;
}

.filter-header h4 {
  margin: 0;
  font-size: 2.7vh;
}

.cafe-rating {
  display: flex;
  width: auto;
  padding: 0 1.7vh;
  border-left: 1px solid #c1c1c1;
  color: #c1c1c1;
  font-size: 1.5vh;
  justify-content: center;
}

.location-pic-wrapper {
  display: flex;
  align-items: center;
}

span.discount,
.cafe-pricing {
  font-size: 1.5vh;
  color: #c1c1c1;
}

.cafe-pricing {
  padding-right: 1.2vh;
}

span.discount {
  padding-left: 1.2vh;
  border-left: 1px solid #c1c1c1;
}

.cafe-rating img {
  height: 1.4vh !important;
  width: auto !important;
  margin: 0 0 0 0.5vh;
  border-radius: 0 !important;
}

.label.i {
  background: #1d1d1b;
  color: #ededed;
}
.label.r {
  background: #aeccc6;
  color: #1d1d1b;
}
.label.d {
  background: #cbd9e6;
}
.label.p {
  background: #ffd2a8;
}

small {
  display: block;
  font-size: 1.6vh;
  line-height: 0.8;
  padding-top: 0.7vh;
}

img.list-logo {
  height: 5vh;
  width: auto;
  display: block;
  margin: 5vh auto 0;
}

.wrapper {
  position: relative;
  width: 100%;
  z-index: 1;
  top: 0;
  background: #ededed;
  height: auto;
}

.wrapper.dark {
  background: black;
}

small.current-location,
.map-text {
  padding: 1.9vh 2.5vh 0;
  font-size: 1.65vh;
  color: #908f8d;
  line-height: normal;
}

img.loc {
  height: 2.3vh;
  width: 2.3vh;
}

nav img.logo {
  height: 3.8vh;
  width: auto;
  z-index: 20;
}

.back2 {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.21) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  backdrop-filter: blur(3px);
  height: 12vh;
  width: 100%;
  position: fixed;
  bottom: 0;
}

.cities-header img.back,
.filter-header img.back {
  height: 3.4vh;
  width: auto;
  margin: 0 1.2vh 0 -1vh;
}

.back2 img {
  position: relative;
  top: 50%;
  left: 2.5vh;
  transform: translateY(-50%);
  height: 7.4vh;
  width: auto;
}

.content {
  background: #ededed;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
  top: 0;
  padding-top: 7.7vh;
}


.content.dark {
  background: black;
}

.fav-cafes-map {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 1vh 2.5vh 2vh 0;
  margin: 0 0 0 2.5vh;
  border-bottom: 1px solid #c4c4c4;
}

.fav-cafes-map.dark {
  border-bottom:1px solid #908F8D
}


.fav-cafes-map h4 {
  margin: 0;
  font-size: 2.2vh;
}

.fav-cafes-map h4.dark {
  color: #ededed;
}

.fav-cafes-map span {
  font-size: 1.7vh;
  color: #908f8d;
}

.cafe-categories {
  display: flex;
  padding-top: 1.2vh;
  overflow: auto;
}

.line {
  width: 100%;
  margin-left: 2.5vh;
  z-index: 0;
  top: 35vh;
  position: absolute;
  border-top: 1px solid #c4c4c4;
}

.line.dark {
   border-top: 1px solid #908F8D;
}

.no-border {
  border: none;
}

.item:first-child {
  margin-left: 2.5vh;
}

.item img {
  display: block;
  height: 11.7vh;
  margin-right: 1vh;
  width: auto;
}

span.category-title {
  display: block;
  color: #908f8d;
  font-size: 1.6vh;
  margin-left: -1vh;
  padding: 1.2vh 0 1.8vh;
  text-align: center;
}

span.category-title.dark {
  color: #908f8d;
}

.category-active {
  color: #1d1d1b !important;
}

.category-active.dark {
  color: #ededed !important;
}

.cards {
  animation: slideup 1s;
  width: auto;
  margin-left: 1.3vh;
  padding-bottom: 13vh;
}

@keyframes slideup {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(inherit);
    opacity: 1;
  }
}

.card-box {
  position: relative;
  cursor: pointer;
  display: flex;
  width: 100%;
  height: auto;
  margin: 1.2vh 0 0 0;
  border-radius: 1.2vh 0 0 1.2vh;
  transition: 0.3s;
  flex-flow: column wrap;
}

.card-box img {
  height: 22vh;
  width: 100%;
  object-fit: cover;
  border-radius: 1.2vh 0 0 1.2vh;
  border-bottom-left-radius: 0;
}

img.icon {
  width: auto;
  margin-right: 0.5vh;
  height: 1.5vh;
  border-radius: 0;
}

input.search {
  width: 100%;
  background: transparent;
  display: block;
  font-size: 2.3vh;
  padding: 0 0 0 1vh;
  border: none;
  outline: none;
  color: #1d1d1d;
}

input.search.dark {
}

.filter-wrapper {
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

.filter-wrapper .search-block.right {
  right: 0;
}

.search-block {
  text-align: center;
  display: flex;
  position: relative;
  border-radius: 1.2vh 0 0 1.2vh;
  background: transparent;
  border: 1px solid #908f8d;
  padding: 0.6vh 1.2vh;
  margin: 0 -1px 0 0;
  width: 5.5vh;
}

.search-block.dark {
  /* background: black; */
}

.search-block.right {
  transition: 0.5s;
  border: 1px solid #908f8d;
  right: 0;
}

.search-block.right.active {
  right: -3vh;
  width: 85vw;
  background: #ededed;
}

img.search-icon {
  height: 2.7vh;
  width: auto;
  position: absolute;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
}

img.filter {
  height: 2.7vh;
  margin: 0 2vh 0 0;
  width: auto;
}

img.close {
  height: 2vh;
  margin: 0 2vh;
  opacity: 1;
  width: auto;
}

.info {
  position: relative;
  width: 100%;
  border-radius: 0 0 0 1.2vh;
  color: #1d1d1d;
  background: white;
  padding: 1.3vh 1.9vh;
}

.info.dark {
  background: #1d1d1d;
  color: #ededed;
}

.info h4 {
  font-size: 2.5vh;
  margin: 0;
  line-height: normal;
}

.loc-box {
  display: flex;
  align-items: center;
  align-self: center;
  height: inherit;
  justify-content: center;

  width: 100%;
  height: 4.8vh;
  left: 0;
  right: 0;
  cursor: pointer;
  position: absolute;
}

nav span {
  display: block;
  color: #ededed;

  font-size: 3vh;
}

.info-schedule-closed span {
  display: flex;
  align-items: center;
}

.error-block {
  color: #e2223b;
  text-align: center;
  margin-top: 15vh;
}

.error-block img {
  width: 40vh;
}

.no-cafes-container {
  color: #908f8d;
  text-align: center;
  position: relative;
  top: 1vh;
  left: 0;
  right: 0;
  width: 100%;
}

.no-cafes-container h4 {
  margin: 0;
  font-size: 2.5vh;
  padding: 1.1vh;
}

.no-cafes-container img {
  width: 35vh;
  height: auto;
}

.loading-panda {
  width: auto;
  height: 10vh;

  margin-top: 1vh;
}

.list-loader {
  text-align: center;
  margin-top: 12vh;
}

.current-filter {
  color: #129b81 !important;
}

.main-cafe-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-flow: row wrap;
}

.sub-info-box {
  display: flex;
  line-height: 1;
}

.cafe-name-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-right: 1vh;
  font-size: 2.2vh;
  text-overflow: ellipsis;
  width: 55%;
  line-height: normal;
}

.info-schedule-closed {
  color: #e2223b !important;
  display: flex;
  align-items: center;
   padding-top: 0.6vh;
}

.order-status-backdrop {
  position: absolute;
  background: rgba(29, 29, 27, 0.2);
  backdrop-filter: blur(20px);
  top: 8vh;
  z-index: 0;
  width: 100vh;
  height: 100vh;
  left: 0;
}

@keyframes slide {
  0% {
    top: -20vh;
  }
  100% {
    top:0;
  }
}
</style>

<script>
import MarketplaceAPI from "@/api/marketplace";

import ScheduleService from "@/services/schedule";

import MarketplaceCitySelector from "@/components/marketplace/MarketplaceCitySelector.vue";
import GeolocationService from "@/services/geolocation";

import MarketplaceGeoModal from "@/components/marketplace/MarketplaceGeoModal.vue";
import OrderStatusNotification from "@/components/OrderStatusNotification.vue";
import MarketplaceFilters from "@/components/marketplace/MarketplaceFilters.vue";
import MarketplaceMap from "@/components/marketplace/MarketplaceMap.vue";
import MarketplaceChainCard from "@/components/marketplace/MarketplaceChainCard.vue";

import MarketplaceService from "@/services/marketplace";

import SnakeGame from "@/components/SnakeGame.vue";

import SupportIcon from "@/components/SupportIcon.vue";

export default {
  data() {
    return {
      server_time: Date.now(),
      list: [],
      cities: [],
      food_flavors: [],
      flavor_cities: [],
      search: null,
      busy: true,
      error: null,
      city_search: null,

      playing_snake: false,

      city_selector_modal: false,
      selected_city: "",
      geo_city_cache: null,
      geo_modal: false,
      current_geo_position: null,
      categoryTitle: "Pizza",
      show_filters: false,
      citiesList: false,
      current_filter: {
        range: 5,
        city: 'geo'
      },
      searchActive: false,
      selected_food_flavor: null,
      showing_map: false,
      geo_denied: false,
      has_web_status: false,
      show_web_status: false,
      hideBanner:false,
      distances_map: {},
      web_status_time: null,
      first_list_fetched: false
    };
  },
  props: {
    mode: String,
  },
  watch: {
    selected_city(n, o) {
      this.fetchList();
    },
    search() {
      this.fetchList();

      if (this.search && this.search.length >= 2) window.fbq('track', 'Search', {
        content_category: 'marketplace_cafes',
        search_string: this.search
      });
    },
    show_web_status() {
      this.toggleGlobalScrolling(
        this.show_web_status,
        true
      );
    }
  },
  methods: {
    fetchCities() {
      MarketplaceAPI.getCities()
        .then((data) => {
          this.cities = data.cities;
          this.flavor_cities = data.flavor_cities;

          const cached_city = window.localStorage.getItem(
            "marketplace_city_cache"
          );

          if (cached_city && this.cities.includes(cached_city)) {
            this.selected_city = cached_city;
            // this.geo_city_cache = cached_city;
          }
        })
        .catch((error) => {
          this.error = error;
        });
    },
    fetchList() {
      this.error = null;
      // if (!this.selected_city) {
      //   this.busy = false;
      //   return;
      // }
      if (!this.search) this.busy = true;

      // let target_city = this.selected_city;

      // if (this.current_filter && this.current_filter.city == 'geo') {
      //   target_city = this.geo_city_cache;
      // } else if (this.current_filter && this.current_filter.city) {
      //   target_city = this.current_filter.city;
      // } else {
      //   target_city = undefined;
      // }

      MarketplaceAPI.getList({
        pos: this.current_geo_position || {},
        city: this.selected_city,
        modes: this.current_filter ? this.current_filter.mode : null,
        price_levels: this.current_filter
          ? this.current_filter.price_level
          : null,
        search: this.search,
        range: this.current_filter ? this.current_filter.range : null,
      })
        .then((data) => {
          this.list = data.list;
          this.server_time = data.timestamp;
          this.distances_map = data.distances_map || {};

          this.processSchedules();
          this.busy = false;


          if (!this.first_list_fetched) {
             if (this.$route.query.from_map) this.showing_map = true;
          }

          this.first_list_fetched = true;
        })
        .catch((error) => {
          this.error = error;
        });
    },
    processSchedules() {
      this.list.forEach((cafe, index) => {
        if (cafe.entry_type != 'single') {
          this.$set(this.list[index], "schedule_verdict", {
            open: true,
            text: null
          });
          return;
        };

        const helper = new ScheduleService.ScheduleHelper(cafe.schedule);

        const schedule_verdict = {
          open: helper.isOpen(),
          text: helper.format(),
          workday: helper.isWorkday(),
          time_left: helper.remainingTime(
            this.$t('units.hours'),
            this.$t('units.minutes')
          ),
          open_hours: helper.hoursTillOpen(),
          is_24hr: cafe.schedule_status.is_24hr
        };

        if (schedule_verdict.is_24hr) {
          schedule_verdict.text = '00:00 - 24:00';
        }

        this.$set(this.list[index], "schedule_verdict", schedule_verdict);
      });

      this.list = [].concat(
        this.list.filter((c) => c.schedule_verdict.open),
        this.list.filter((c) => !c.schedule_verdict.open)
      );
    },
    backToModes() {
      this.$emit("back");
    },
    navigateToCafe(cafe) {
      if (
        cafe.modes.inside &&
        !cafe.modes.preorder &&
        !cafe.modes.delivery &&
        !cafe.modes.reserve
      ) {
        return;
      }

      window.fbq('track', 'ViewContent', {
        content_ids: [cafe._id],
        content_name: cafe.name,
        content_category: 'marketplace_cafe_open'
      });

      MarketplaceService.set(cafe.online_menu_link, true);

      if (`${cafe.online_menu_link}` == "djigit") {
        window.location.href = `https://cafe.qrwaiter.com.ua/${cafe.online_menu_link}`;
        return;
      }

      this.$router.push({
        name: "choose_mode",
        params: {
          code: cafe.online_menu_link,
        },
        query: {
          from_map: this.showing_map ? '1' : undefined
        }
      });
    },
    formatAddress(raw) {
      if (!raw) return "";
      let first = raw
        .split(",")
        .map((s) => s.trim())
        .slice(0, 2)
        .join(",");
      const street_names = ["вул.", "ул.", "вулиця", "улица"];

      street_names.forEach((s) => (first = first.replace(s, "")));

      return first;
    },
    openCitiesList() {
      this.citiesList = true;
      this.show_filters = true;
      this.$nextTick(() => {
        this.$refs.filtersBlock.showCitiesListUI();
      });
    },
    showCitySelector() {
      // if (!this.$route.query.uzh) this.city_selector_modal = true;
      this.city_selector_modal = true;
    },
    onCitySelect(new_city) {
      this.selected_city = new_city;
      this.city_selector_modal = false;
    },
    hideCitiesList() {
      this.citiesList = false;
      this.$refs.filtersBlock.hideCitiesList();
    },
    onCityGeoSelect() {
      GeolocationService.getPosition()
        .then((pos) => {
          this.current_geo_position = pos;
          window.localStorage.setItem(
            `last_marketplace_geo`,
            JSON.stringify({
              pos,
              timestamp: new Date(),
            })
          );
          this.city_selector_modal = false;
          this.fetchList();
          this.detectCity(pos);
        })
        .catch((error) => {});
    },
    onGeoConfirm(pos) {
      this.current_geo_position = pos;
      this.geo_denied = false;
      this.detectCity(pos);
      this.geo_modal = false;
      this.fetchList();

      window.localStorage.setItem("marketplace_geo_granted", Date.now());
    },
    formatCafeName(cafe) {
      if (cafe.name.length < 17) return cafe.name;

      return cafe.name.substring(0, 18) + "...";
    },
    detectCity(pos) {
      if (this.geo_city_cache) {
        this.selected_city = this.geo_city_cache;
        return;
      }

      const geocoder = new window.google.maps.Geocoder();

      // const cached_city = window.localStorage.getItem("marketplace_city_cache");

      // if (cached_city) {
      //   return;
      // }

      geocoder.geocode(
        {
          location: {
            lat: pos.lat,
            lng: pos.lon,
          },
        },
        (results, status) => {
          if (status === "OK") {
            const first_result = results[0];

            const components = first_result.address_components;

            const political = components.find((c) =>
              c.types.includes("political")
            );

            if (!political) return;

            const city = political.long_name;

            console.log(`Geocoded city: ${city}`);

            this.geo_city_cache = city;

            // this.selected_city = city;
          }
        }
      );
    },
    onGeoDeny() {
      this.current_geo_position = {
        // lat: 48.620889,
        // lon: 22.297451,
        lat: null,
        lon: null,
      };
      this.geo_denied = true;
      this.geo_modal = false;
      this.selected_city = null;
      // this.city_selector_modal = true;
      this.citiesList = true;
      this.openCitiesList();
      window.localStorage.setItem("marketplace_geo2", "user_denied");
    },
    checkGeoCache() {
      if (this.$route.query.uzh) {
        this.current_geo_position = {
          lat: 48.620889,
          lon: 22.297451,
        };

        this.detectCity(this.current_geo_position);
        this.geo_modal = false;
        return;
      }

      if (this.$route.query.mukachevo) {
        this.current_geo_position = {
          lat: 48.443439,
          lon: 22.717066,
        };

        this.detectCity(this.current_geo_position);
        this.geo_modal = false;
        return;
      }

      const cached_city = window.localStorage.getItem("marketplace_city_cache");

      if (cached_city) {
        this.selected_city = cached_city;
        return;
      }

      if (this.geo_city_cache) return;

      const permission = window.localStorage.getItem("marketplace_geo_granted");

      if (!permission) {
        this.geo_modal = true;
      } else {
        const diff = Date.now() - +permission;

        if (diff > 1 * 24 * 60 * 60 * 1000) {
          window.localStorage.removeItem("marketplace_geo_granted");
          this.geo_modal = true;
          return;
        }

        this.onCityGeoSelect();
      }
    },
    showFiltersUI() {
      this.show_filters = true;
    },
    onCurrentGeo() {
      this.show_filters = true;
      this.citiesList = true;
    },
    selectLocation() {
      this.filterActive = true;
      this.show_filters = false;
      this.citiesList = false;
    },
    onApplyFilters(filters) {
      this.current_filter = filters;

      if (this.current_filter && this.current_filter.city == "geo") {
        this.selected_city = null;
        window.localStorage.removeItem("marketplace_city_cache");
        

        if (this.geo_denied) {
          this.geo_modal = true;
        } else {
          this.onCityGeoSelect();
        }
      } else if (this.current_filter && this.current_filter.city) {
        this.selected_city = this.current_filter.city;
        this.current_filter.range = null;
        window.localStorage.removeItem("last_marketplace_geo");
      } else {
        this.selected_city = null;
        window.localStorage.removeItem("marketplace_city_cache");
      }

      if (!this.geo_modal) this.fetchList();
      this.show_filters = false;
      // if (!filters.mode && !filters.price_level) {
      //   this.current_filter = null;
      // }
      this.city_selector_modal = false;
      this.citiesList = false;
    },
    fetchFlavors() {
      MarketplaceAPI.getFlavors()
        .then((data) => {
          this.food_flavors = data.flavors;
        })
        .catch((error) => {});
    },
    onSelectFlavor(flavor) {
      if (this.selected_food_flavor == flavor._id) {
        this.selected_food_flavor = null;
      } else {
        this.selected_food_flavor = flavor._id;
      }
    },
    getFlavorDisplayName(flavor) {
      if (!flavor.i18n) return flavor.name;
      const translation = flavor.i18n[this.$i18n.locale];

      return translation || flavor.name;
    },
    getFlavorImage(flavor) {
      //forgive me for that
      if (this.selected_food_flavor == flavor._id) {
        if (this.isDarkMode) {
          return flavor.image_new_dark_active || flavor.image_active;
        } else {
          return flavor.image_new_active || flavor.image_active;
        }
      } else {
        if (this.isDarkMode) {
          return flavor.image_new_dark || flavor.image;
        } else {
          return flavor.image_new || flavor.image;
        }
      }
    }
  },
  computed: {
    localCafes() {
      return this.list;
    },
    filteredCafes() {
      let list = this.localCafes;

      if (this.selected_food_flavor) {
        list = list.filter((cafe) => {
          if (!cafe.food_flavors) return false;
          return cafe.food_flavors.includes(this.selected_food_flavor);
        });
      }

      return list;

      if (this.current_filter) {
        list = list.filter((cafe) => {
          if (
            this.current_filter.price_level &&
            cafe.price_level != this.current_filter.price_level
          )
            return false;
          if (this.current_filter.mode && !cafe.modes[this.current_filter.mode])
            return false;

          return true;
        });
      }

      if (this.selected_food_flavor) {
        list = list.filter((cafe) => {
          if (!cafe.food_flavors) return false;
          return cafe.food_flavors.includes(this.selected_food_flavor);
        });
      }

      if (!this.search) return list;

      const q = this.search.toLowerCase();

      return list.filter((cafe) => {
        const name_lower = cafe.name.toLowerCase();
        const desc_lower = cafe.description
          ? cafe.description.toLowerCase()
          : "";
        const keywords_lower = cafe.keywords ? cafe.keywords.toLowerCase() : "";
        const price_level = cafe.price_level || "";

        return (
          name_lower.includes(q) ||
          desc_lower.includes(q) ||
          keywords_lower.includes(q) ||
          price_level.includes(q)
        );
      });
    },
    isGeoCity() {
      return this.selected_city == this.geo_city_cache;
    },
    nonEmptyFoodFlavors() {
      if (!this.isCityEnabled) return [];

      return this.food_flavors.filter((flavor) => {
        return !!this.localCafes.find(
          (c) => c.food_flavors && c.food_flavors.includes(flavor._id)
        );
      });
    },
    isCityEnabled() {
      // if (!this.selected_city) return false;

      return this.flavor_cities.includes(this.selected_city || this.geo_city_cache);
    },
    allFlavorsImage() {
      if (this.isDarkMode) {
        if (this.selected_food_flavor) {
          return '/icons/marketplace/flavor-all-mobile-dark.png';
        } else {
          return '/icons/marketplace/flavor-all-mobile-dark-active.png';
        }
      } else {
        if (this.selected_food_flavor) {
          return '/icons/marketplace/flavor-all-mobile.png';
        } else {
          return '/icons/marketplace/flavor-all-mobile-active.png';
        }
      }
    }
  },
  mounted() {
    this.fetchFlavors();
    this.checkGeoCache();
    // this.fetchList();
    this.fetchCities();

    this.$store.dispatch("clients/init");
  },
  components: {
    MarketplaceCitySelector,
    MarketplaceGeoModal,
    MarketplaceFilters,
    MarketplaceMap,
    MarketplaceChainCard,
    OrderStatusNotification,
    SnakeGame,
    SupportIcon,
  },
};
</script>
