<template>
  <div class="geo-modal-wrapper">
    <div class="geo-hide-content"></div>
    <div class="geo-wrapper" v-if="!error">
      <div class="geo-modal-content">
        <p class="geo-hint">{{ $t("marketplace_geo_modal.hint") }}</p>
        <img class="geo-hero-img" src="/icons/geo-hero.svg" />
        <h4
          class="geo-title color-white"
          v-html="$t('marketplace_geo_modal.title')"
        ></h4>
      </div>
      <LongButton
        class="geo-confirm-button rect-borders mt-20"
        @click="onConfirm"
      >
        {{ $t("marketplace_geo_modal.ok") }}
      </LongButton>
      <LongButton class="deny-geo rect-borders" @click="onDeny">
        {{ $t("marketplace_geo_modal.deny") }}
      </LongButton>
    </div>

    <div class="geo-wrapper" v-if="error">
      <div class="geo-modal-content">
        <p class="geo-hint">
          {{ $t("marketplace_geo_modal.error_text") }}
        </p>
        <img class="geo-hero-img" src="../../assets/error-hero.svg" />
        <h4 class="geo-title color-white">
          {{ $t("marketplace_geo_modal.denied") }}
        </h4>
      </div>
      <LongButton
        variant="danger"
        class="geo-confirm-button mt-20 rect-borders"
        @click="onConfirm"
      >
        {{ $t("marketplace_geo_modal.try_again") }}
      </LongButton>
      <LongButton class="deny-geo rect-borders" @click="onDeny">
        {{ $t("marketplace_geo_modal.deny") }}
      </LongButton>
    </div>
  </div>
</template>

<script>
import GeolocationService from "@/services/geolocation";

export default {
  data() {
    return {
      error: null,
    };
  },
  methods: {
    onConfirm() {
        if (this.error) {
            window.location.reload();
            return;
        }
      GeolocationService.getPosition()
        .then((pos) => {
          this.$emit("confirm", pos);
        })
        .catch((error) => {
          this.error = error;
        });
    },
    onDeny() {
      this.$emit("deny");
    },
  },
  mounted() {
    this.toggleGlobalScrolling(true);
  },
  beforeDestroy() {
    this.toggleGlobalScrolling(false);
  },
};
</script>

<style scoped>


.mt-20 {
  margin-top: 2.5vh;
}

.geo-wrapper {
  position: fixed;
  top: 50%;
  width: 90%;
  z-index: 5;
  margin: 0 auto;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.geo-modal-content {
  padding: 2.5vh;
  text-align: center;
  background: #1d1d1b;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2.2vh;

  z-index: 10;
}

.geo-hide-content {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 3;
  overflow: hidden;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.4);
  opacity: 1;
  top: 0;
  left: 0;
}

.geo-hero-img {
  height: auto;
  margin-top: 3.7vh;

  width: 25vh;
}

.geo-title {
  margin: 0;
  font-weight: 400;
  padding-top: 2.5vh;
  font-size: 2.2vh;
}

.deny-geo {
  margin-top: 1.7vh;
  background: transparent;
  border: 1px solid #ededed;
}

.geo-hint {
  font-weight: 400 !important;
  color: #a4a3a1;
  font-size: 2vh;
  padding: 0;
}

.geo-confirm-button,
.deny-geo {
  width: 100%;
}

@media screen and (min-width:768px) {
  .geo-wrapper {
    width: 60%;
  }
}

 
</style>