<template>
  <div class="slidecontainer">
    <input
      type="range"
      :min="min"
      :max="max"
      :value="value"
      class="slider"
      @input="ev => $emit('input', ev.target.value)"
    />
  </div>
</template>
<script>
export default {
  props: {
    value: Number,
    min: Number,
    max: Number
  },
  data() {
    return {
    };
  }
};
</script>
<style scoped>
.slidecontainer {
  width: 100%;
}
.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 1.11111vh;
  background: #aeccc6;
  outline: none;
  border-radius: 0.2222vh;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 2.5555555555555554vh;
  height: 2.5555555555555554vh;

  cursor: pointer;
  background: #129b81;
  border-radius: 0.4444vh;
}

.slider::-moz-range-thumb {
  width: 2.5555555555555554vh;
  height: 2.5555555555555554vh;
  background: #129b81;
  cursor: pointer;
}
</style>
