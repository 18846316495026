<template>
  <div class="marketplace-cafe-card-desktop">
    <div class="connected-systems-container" v-if="!isChain">
      <div
        class="connected-system-block system-color1"
        v-if="cafe.modes.delivery"
      ></div>
      <div
        class="connected-system-block system-color2"
        v-if="cafe.modes.preorder"
      ></div>
      <div
        class="connected-system-block system-color3"
        v-if="cafe.modes.reserve"
      ></div>
      <div
        class="connected-system-block system-color4"
        v-if="cafe.modes.inside"
      ></div>
    </div>
    <img
      class="cafe-image"
      :src="cafe.logo || cafe.image || '/icons/cafe-placeholder.jpg'"
      alt=""
    />
    <div class="card-content">
      <div>
        <div class="title-container">
          <div class="big-text black-text">{{ cafe.name }}</div>
          <div class="flex-box">
            <span class="small-text grey-text" v-if="cafe.price_level">
              {{ cafe.price_level }} </span
            ><span class="small-text grey-text" v-if="cafe.reviews_rating"
              >{{ cafe.reviews_rating }}
              <img src="/icons/desktop/small-rate-icon.svg" alt=""/></span
            ><span class="small-text grey-text" v-if="cafe.cashback_percent">
              {{ cafe.cashback_percent }}%
            </span>
          </div>
        </div>
        <div v-if="!isChain">
          <div
            class="icon-text-block small-text"
            :class="{
              'cafe-closed': !scheduleVerdict.open,
            }"
          >
            <img
              :src="
                scheduleVerdict.open
                  ? '/icons/desktop/timing-icon.svg'
                  : '/icons/desktop/timing-icon-red.svg'
              "
              alt=""
            />
            {{
              scheduleVerdict.workday
                ? (scheduleVerdict.is_24hr ? '00:00 - 24:00' : cafe.schedule_status.working_times)
                : $t("marketplace.list.day_off")
            }}
          </div>
          <div class="small-text grey-text mb-10" v-if="scheduleVerdict.open && scheduleVerdict.is_24hr">
            {{ $t("marketplace.list.open_24hr") }}
          </div>
          <div class="small-text grey-text mb-10" v-else-if="scheduleVerdict.open">
            {{ scheduleVerdict.time_left }}
            {{ $t("marketplace.list.closes_in") }}
          </div>
          <div class="small-text grey-text mb-10" v-else>
            {{ scheduleVerdict.open_hours }}
            {{ $t("marketplace.list.opens_in") }}
          </div>
          <div class="icon-text-block small-text">
            <img src="/icons/desktop/location-icon.svg" alt="" />
            {{ cafe.address }}
          </div>
          <div class="small-text grey-text mb-15" v-if="cafe.distance">
            {{ cafe.distance }} {{ $t("marketplace.list.distance") }}
          </div>
        </div>
        <div v-else class="icon-text-block small-text black-text">
          <img src="icons/desktop/cafe-card-chain-icon.svg" alt="" />{{
            cafe.cafes.length
          }}
          {{ $t('marketplace.chain.count') }}
        </div>
      </div>
      <div class="button" @click="openCafe">
       {{  isChain ? $t('marketplace.list.goto_chain') : $t('marketplace.list.goto_menu') }}
        <img src="/icons/desktop/cafe-card-arrow-icon.svg" alt="" />
        <img src="/icons/desktop/cafe-card-arrow-icon-white.svg" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import MarketplaceService from "@/services/marketplace";
import ScheduleService from "@/services/schedule";

export default {
  props: {
    cafe: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  methods: {
    openCafe() {
      if (this.isChain) {
        this.$emit('chainSelected', this.cafe.cafes);
        return;
      }

      MarketplaceService.set(this.cafe.online_menu_link, true);

      this.$router.push({
        name: "choose_mode",
        params: {
          code: this.cafe.online_menu_link,
        },
      });
    },
  },
  computed: {
    scheduleVerdict() {
      const helper = new ScheduleService.ScheduleHelper(this.cafe.schedule);

      const schedule_verdict = {
        open: helper.isOpen(),
        text: helper.format(),
        workday: helper.isWorkday(),
        time_left: helper.remainingTime(),
        open_hours: helper.hoursTillOpen(),
        is_24hr: this.cafe.schedule_status && this.cafe.schedule_status.is_24hr
      };

      if (schedule_verdict.is_24hr) {
        schedule_verdict.open_hours = '00:00 - 24:00';
      }

      return schedule_verdict;
    },
    isChain() {
      return this.cafe.entry_type == 'chain';
    }
  },
};
</script>
<style scoped>
.marketplace-cafe-card-desktop {
  width: 100%;
  height: 21.444444444444443vh;
  background: #f8f8f8;
  border-radius: 1.111vh;
  display: flex;
  overflow: hidden;
  margin: 0 0 2.2222vh;
  position: relative;
}
.cafe-image {
  height: 100%;
  width: 50%;
  object-fit: cover;
  object-position: center;
}
.card-content {
  width: 50%;
  padding: 1.6666666666666667vh 2.7777777777777777vh 2.2222222222222223vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.connected-systems-container {
  position: absolute;
  top: 0;
  right: 50%;
  padding: 1.1111vh;
  z-index: 2;
}
.flex-box {
  display: flex;
}
.connected-system-block {
  min-width: 1.1111vh;
  min-height: 1.1111vh;
  border-radius: 0.1111vh;
  margin-bottom: 0.6666666666666666vh;
}
.system-color1 {
  background: #cbd9e6;
}
.system-color2 {
  background: #ffd2a8;
}
.system-color3 {
  background: #aeccc6;
}
.system-color4 {
  background: #1d1d1b;
}
.big-text {
  font-weight: normal;
  font-size: 2vh;
  line-height: 2.3333333333333335vh;
}
.small-text {
  font-size: 1.2222222222222223vh;
  line-height: 1.4444444444444444vh;
}

.black-text {
  color: #1d1d1b;
}
.grey-text {
  color: #c1c1c1;
}
.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.6666666666666667vh;
}
span {
  padding: 0 1.1111vh;
  border-right: 0.111vh solid #c1c1c1;

  align-items: center;
}
span:last-child {
  border: none;
}
span img {
  width: 1vh;
}
.icon-text-block {
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0.5555555555555556vh;
  color: #464646;
}
.icon-text-block img {
  height: 1.4444444444444444vh;
  margin-right: 0.5555555555555556vh;
}
.mb-10 {
  margin-bottom: 1.1111vh;
}
.mb-15 {
  margin-bottom: 1.6666666666666667vh;
}
.button {
  width: 100%;
  padding: 0.8888888888888888vh 1.6666666666666667vh;
  border: 0.1111vh solid #908f8d;
  box-sizing: border-box;
  border-radius: 0.5555555555555556vh;
  font-size: 1.5555555555555556vh;
  line-height: 100%;
  color: #908f8d;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.button img {
  height: 1.2222222222222223vh;
}
.button img:last-child {
  display: none;
}
.button:hover {
  background: #1d1d1b;
  color: #ededed;
  border-color: #1d1d1b;
  cursor: pointer;
}
.button:hover img:last-child {
  display: block;
}
.button:hover img:first-child {
  display: none;
}

.cafe-closed {
  color: #e2223b;
}
</style>
