<template>
  <div>
    <div class="filters-box" v-thematic>
      <div class="wrapper" v-if="!showCitiesList">
      <h5 class="category-header" v-thematic>
        {{ $t('marketplace.filters.by_city') }}
        <img src="/icons/cross-white.svg" v-show="false" />
      </h5>
      <div :class="[filter_data.city ? 'choose-geo active':'choose-geo',filter_data.city == 'geo' ? 'default-bg':'']" @click="emitOnCurrentGeo()" v-thematic>
        <span
          class="my-location"
          @click="emitOnCurrentGeo()"
          v-if="filter_data.city == 'geo'"
        >{{ $t('marketplace.filters.geo_city') }}</span>
        <span
         :class="filter_data.city ? 'active-city':''"
          @click="emitOnCurrentGeo()"
          v-if="filter_data.city && filter_data.city != 'geo'"
        >{{ filter_data.city}}</span>
        <span
          @click="emitOnCurrentGeo()"
          v-if="!filter_data.city"
        >{{ $t('marketplace.filters.all_cities') }}</span>
         
      
        <img v-if="filter_data.city == 'geo'" src="/icons/arrow-white.svg" />
        <img v-else :src="filter_data.city ? '/icons/arrow-black.svg':'/icons/arrow-white.svg'" />
      </div>

      <LocationRangeFilter 
        v-model="filter_data.range" 
        :count="distances_map[filter_data.range] || 0"
        v-if="filter_data.city == 'geo'"/>

      <h5 class="category-header pt-30" v-thematic>{{ $t('marketplace.filters.by_mode') }}</h5>
      <div class="category-selector-box">
        <FilterRadioButton v-model="filter_data.mode" :raw="'inside'">
          <div class="mode-wrapper">
            <span>INSIDE</span>
            <small>{{ $t('marketplace.filters.sys_inside') }}</small>
          </div>
        </FilterRadioButton>
        <FilterRadioButton v-model="filter_data.mode" :raw="'preorder'">
           <div class="mode-wrapper">
            <span>TAKEAWAY</span>
            <small>{{ $t('marketplace.filters.sys_preorder') }}</small>
          </div>
        </FilterRadioButton>
        <FilterRadioButton v-model="filter_data.mode" :raw="'delivery'">
           <div class="mode-wrapper">
            <span>DELIVERY</span>
            <small>{{ $t('marketplace.filters.sys_delivery') }}</small>
          </div>
        </FilterRadioButton>
        <FilterRadioButton v-model="filter_data.mode" :raw="'reserve'">
          <div class="mode-wrapper">
            <span>RESERVE</span>
            <small>{{ $t('marketplace.filters.sys_reserve') }}</small>
          </div>
        </FilterRadioButton>
      </div>

      <h5 class="category-header pt-30" v-thematic>{{ $t('marketplace.filters.by_price') }}</h5>
      <div class="category-selector-box">
        <FilterRadioButton v-model="filter_data.price_level" :raw="'$'">$</FilterRadioButton>
        <FilterRadioButton v-model="filter_data.price_level" :raw="'$$'">$$</FilterRadioButton>
        <FilterRadioButton v-model="filter_data.price_level" :raw="'$$$'">$$$</FilterRadioButton>
      </div>
      </div>

      <MarketplaceCityFilters
        :all_cities="all_cities"
        :city_search="city_search"
        :current_city="filter_data.city"
        @geo="onCityGeo"
        @city="onCitySelect"
        v-if="showCitiesList"
      />
    </div>
    <button 
      class="btn-filter" 
      v-if="!showCitiesList"
      @click="onApply">{{ $t('marketplace.filters.apply') }}</button>
  </div>
</template>

<script>
import FilterRadioButton from "@/components/marketplace/FilterRadioButton.vue";
import MarketplaceCityFilters from "@/components/marketplace/MarketplaceCityFilters.vue";
import LocationRangeFilter from "@/components/marketplace/LocationRangeFilter.vue";

export default {
  data() {
    return {
      showCitiesList: false,
      filter_data: {
        mode: null,
        price_level: null,
        city: "geo",
        range: 5
      },
    };
  },
  props: {
    all_cities: Array,
    city_search: String,
    initial_filters: Object,
    distances_map: Object
  },
  methods: {
    emitOnCurrentGeo() {
      this.$emit("currentGeo");
      this.showCitiesList = true;
    },
    emitSelectedLocation() {
      this.$emit("selectedLocation");
    },
    onClose() {
      this.$emit("close");
    },
    onApply() {
      this.$emit("apply", this.filter_data);
      this.showCitiesList = false;

      if (this.filter_data.city && this.filter_data.city !== 'geo') {
        window.localStorage.setItem('marketplace_city_cache', this.filter_data.city);
      } else {
        window.localStorage.removeItem('marketplace_city_cache');
      }
    },
    hideCitiesList() {
      this.showCitiesList = false;
      this.$emit("hideCitiesList");
    },
    onCitySelect(city) {
      this.filter_data.city = city;
      this.hideCitiesList();
    },
    onCityGeo() {
      this.filter_data.city = "geo";
      this.hideCitiesList();
    },
    showCitiesListUI() {
      this.showCitiesList = true;
    },
  },
  mounted() {
    if (this.initial_filters) {
      this.filter_data = Object.assign({}, this.initial_filters);
    }
  },
  // mounted() {
  //   this.toggleGlobalScrolling(true);
  // },
  // beforeDestroy() {
  //   this.toggleGlobalScrolling(false);
  // },
  components: {
    FilterRadioButton,
    MarketplaceCityFilters,
    LocationRangeFilter
  },
};
</script>

<style scoped>
.filters-box {
  background: #ededed;
  z-index: 2;
  overflow: hidden;
  position: relative;
  top: 7.7vh;
  padding: 0 2.5vh 12vh;
  width: 100%;
  /* overflow: hidden; */
}

.mode-wrapper {
  display: flex;
  flex-flow: column;
}

.mode-wrapper span {
  line-height: normal;
}

span.active-city {
  color: #ededed;
}

.filters-box.dark {
  background: black;
}

.pt-30 {
  padding-top: 5vh !important;
}

h5 img {
  float: right;
  margin: -1vh -1vh 0 0;
  height: 4.3vh;
}

h5.category-header:first-child {
  padding-top: 2.5vh;
}

h5.category-header {
  text-transform: uppercase;
  font-size: 1.8vh;
  margin: 0;
  padding: 1.2vh 0;
  color: #464646;
  border-bottom: 1px solid #464646;
}

h5.category-header.dark {
  color: #908f8d;
  border-bottom: 1px solid #908f8d;
}

.choose-geo {
  background: white;
  margin-top: 1.5vh;
  padding: 1.4vh;
  display: flex;
  justify-content: space-between;
  border-radius: 1.5vh;
}

.choose-geo.active, .choose-geo.dark.active  {
  background: #129b81;
}

.default-bg {
  background:#fff !important
}

.choose-geo.dark {
  background: #1d1d1d;
  color: #ededed;
}

.choose-geo span {
  font-size: 2.2vh;
}

.choose-geo.active span {
  color: #ededed;
}

.choose-geo span.my-location {
  color: #1d1d1d;

}

.choose-geo img {
  float: right;
  transform: rotate(-90deg);
}

.item:first-child {
  padding-top: 1.5vh;
}

.item {
  display: flex;
  justify-content: space-between;
  padding: 2.6vh 0 0;
  font-size: 2.2vh;
}

.item.dark {
  color: #908f8d;
}

.circle-action {
  width: 3vh;
  height: 3vh;
  background: transparent;
  border-radius: 5vh;
  border: 1px solid #1d1d1d;
}

.circle-action.dark {
  border: 1px solid #908f8d;
}

.circle-action.active {
  background: #129b81;
  border: 1px solid transparent;
}

.btn-filter {
  width: 90%;
  background: #129b81;
  text-align: center;
  font-size: 2vh;
  padding: 1.6vh 0;
  bottom: 3.2vh;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 2;
  border-radius: 5vh;
  margin: 6.2vh auto 0;
  border: none;
  outline: none;

  color: #ededed;
}
.cities-list {
  height: 100vh;
  background: #ededed;
  position: absolute;
  top: 0;
  color: #1d1d1d;
  z-index: 1;
  width: 99%;
  padding: 0;
  left: 0;
}

.cities-list.dark {
  background: black;
}

.cities-list li.dark {
  color: #908f8d;
  border: 1px solid #908f8d;
}

.cities-list ul {
  text-align: center;
  padding: 2.5vh;
  list-style: none;
  margin: 0;
}
.cities-list li {
  padding: 1.7vh 0;
  background: transparent;
  border: 1px solid #1d1d1d;
  border-radius: 5vh;
  margin-top: 2.5vh;
  font-size: 2vh;
  color: #464646;
}

.cities-list li.active {
  color: #ededed;
  border: 1px solid transparent;
  background: #129b81;
}
</style>

