<template>
  <div class="marketplace-wrapper">
    <MarketplaceMode
      @select="onModeSelect"
      @go2order="go2Order"
      v-if="!show_list"
    />
    <MarketplaceList
      :mode="selected_mode"
      @back="show_list = false"
      v-if="show_list && isMobile"
    />
    <MarketplaceListDesktop
      :mode="selected_mode"
      @back="show_list = false"
      v-else-if="show_list"
    />
  </div>
</template>

<script>
import MarketplaceMode from "@/components/marketplace/MarketplaceMode.vue";
import MarketplaceList from "@/components/marketplace/MarketplaceList.vue";
import MarketplaceListDesktop from "@/components/desktop/MarketplaceListDesktop.vue";

import MarketplaceService from "@/services/marketplace";

import TelemetryService from "@/services/telemetry";

import moment from "moment";

import Polyglote from "@/services/polyglote";

export default {
  data() {
    return {
      show_list: false,
      selected_mode: "delivery",
    };
  },
  components: {
  
    MarketplaceMode,
    MarketplaceList,
    MarketplaceListDesktop,
  },
  methods: {
    onModeSelect(mode) {
      this.selected_mode = mode;
      this.show_list = true;
    },
    go2Order() {
      this.show_list = true;
    },
  },
  mounted() {
    window.document.title = "QRWaiter Marketplace";

    window.fbq('init', '2705660353049930');
    window.fbq('track', 'PageView');

    window.document.body.style.backgroundColor = this.isDarkMode
      ? "black"
      : "#ededed";

    if (this.$route.name == "choose_mode") {
      window.document.body.style.backgroundColor = this.isDarkMode
        ? "black"
        : "#ededed";
    }

    if (this.$route.query.mode) {
      this.selected_mode =
        this.$route.query.mode == "preorder" ? "preorder" : "delivery";
    }

    if (
      this.$route.query.skip ||
      this.$route.query.uzh ||
      this.$route.query.mukachevo
    ) {
      this.show_list = true;
    }

    // if (!this.isMobile) {
    //   this.show_list = true;
    // } else {
    //   window.location.href = "https://qrwaiter.com.ua/visitor";
    //   return;
    // }

    this.show_list = true;

    TelemetryService.init(null, null, "mobile_web");

    window.fbq('track', 'ViewContent');

    this.$store.commit('setAppBannerShown', true);
  },
  beforeDestroy() {
    this.$store.commit('setAppBannerShown', false);
  }
};
</script>
