<template>
  <div 
    :class="{
      'item': true,
      'item-active': value == raw
      }" @click="onSelect" v-thematic>
    <span><slot></slot></span>
    <div :class="{
        'circle-action': true,
        'active': value == raw
    }" v-thematic></div>
  </div>
</template>

<script>
export default {
    props: {
        value: String,
        raw: String
    },
    methods: {
        onSelect() {
          if (this.value === this.raw) {
            this.$emit('input', null);
          } else {
            this.$emit('input', this.raw);
          }
            
        }
    }
};
</script>

<style scoped>
.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.6vh 0 0;
  font-size: 2.2vh;
}

.item {
  color: #908f8d;
}

.item-active {
  color: #1d1d1d;
}

.item-active.dark {
  color: #129b81 !important;
}

.circle-action {
  width: 2.5vh;
  height: 2.5vh;
  background: transparent;
  border-radius: 5vh;
  border: 1px solid #1d1d1d;
}

.circle-action.dark {
  border: 1px solid #908f8d;
}

.circle-action.active {
  background: #129b81;
  border: 1px solid transparent;
}
</style>