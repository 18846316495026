<template>
  <div class="card-box" @click="navigateToChain">
    <img :src="chain.logo ? chain.logo : '/icons/cafe-placeholder.jpg'" />
    <div class="info" v-thematic>
      <div class="main-cafe-wrapper">
        <span class="cafe-name-wrap"> {{ chain.name }} </span>
        <div class="sub-info-box">
          <!-- <span class="cafe-rating">
            
            <img src="../../assets/hand-white.svg" />
          </span> -->
          <span class="cafe-pricing">{{ chain.price_level ||  '$'}}</span>
        </div>
      </div>
      <div class="cafes-chain-box">
        <small v-thematic>
          <img
            src="../../assets/cafes-chain.svg"
            v-if="!isDarkMode"
            class="icon"
          />
          <img src="../../assets/cafes-chain-dark.svg" v-else class="icon" />
          {{ chain.cafes.length }} {{ $t('marketplace.chain.count') }}
        </small>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    chain: Object
  },
  methods: {
    navigateToChain() {
      this.$router.push({
        name: 'chain',
        params: {
          chain_id: this.chain.link
        }
      }).catch(err => {
        console.error(err);
      });
      // window.location.href = `https://app.qrwaiter.com.ua/chain/${this.chain._id}`;
    }
  }
};
</script>

<style scoped>
.card-box {
  position: relative;
  cursor: pointer;
  display: flex;
  width: 100%;
  height: auto;
  margin: 1.2vh 0 0 0;
  border-radius: 1.2vh 0 0 1.2vh;
  transition: 0.3s;
  flex-flow: column wrap;
}

.card-box img {
  height: 21vh;
  width: 100%;
  object-fit: cover;
  border-radius: 1.2vh 0 0 1.2vh;
  border-bottom-left-radius: 0;
}

img.icon {
  width: auto;
  border-radius: 0;
  margin-right: 0.5vh;
  height: 1.5vh;
}

.cafes-chain-box {
  padding-top: 1.4vh;
}

.cafes-chain-box small {
  align-items: center;
}

.cafes-chain-box small.dark {
  color: #908f8d;
}

.info {
  width: 100%;
  position: relative;
  border-radius: 0 0 0 1.2vh;
  color: #1d1d1d;
  background: white;
  padding: 1.7vh 2.3vh 2.5vh 2.3vh;
}

.info.dark {
  background: #1d1d1d;
  color: #ededed;
}

.main-cafe-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.sub-info-box {
  display: flex;
  line-height: 1;
}

.cafe-name-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-right: 1vh;
  font-size: 2.2vh;
  text-overflow: ellipsis;
  width: 55%;
  line-height: normal;
}

.cafe-rating {
  display: flex;
  width: auto;
  padding: 0 1.7vh;
  color: #c1c1c1;
  font-size: 1.6vh;
  justify-content: center;
}

.cafe-pricing {
  font-size: 1.6vh;
  color: #c1c1c1;
  /* border-left: 1px solid #c1c1c1; */
  padding-left: 1.5vh;
}
div small {
  color: #464646;
  padding: 0;
  display: flex;
  font-size: 1.6vh;
  line-height: normal;
}

.cafe-rating img {
  height: 1.4vh !important;
  width: auto !important;
  margin: 0 0 0 0.5vh;
  border-radius: 0 !important;
}
</style>