<template>
  <div>
    <header>
      <img src="/icons/logo.svg" alt />
      <h4>твої улюблені заклади</h4>
      <button class="order-btn" ontouchstart @click="go2Order()">Обрати заклад</button>
    </header>
    <div class="intro-bg"></div>
    <div class="d-flex flex-row desk-view">
      <div class="features">
        <div class="feature-box">
          <h3>INSIDE</h3>
          <p>
            Роби замовлення в
            <br />закладі через QR-код
          </p>
          <img src="/desktop-pics/eat.png" alt />
        </div>
        <div class="feature-box">
          <h3 class="yellow">TAKEAWAY</h3>
          <p>
            Замовляй takeaway
            <br />завчасно
          </p>
          <img src="/desktop-pics/preorder.png" alt />
        </div>
        <div class="feature-box">
          <h3 class="blue">DELIVERY</h3>
          <p>
            Замовляй страви
            <br />з доставкою
          </p>
          <img src="/desktop-pics/deliv.png" class="special" />
        </div>
      </div>
    </div>

    <div class="download-box desk-view">
      <img src="/desktop-pics/demo-system.png" class="demo" />
      <div class="wrapper">
        <h2>
          Завантажуй додаток
          <br />
          <H1>QRDelivery</H1>
        </h2>
        <h4>Скануй QR-код</h4>
        <div class="available">
          <img src="/desktop-pics/qr-link.png" class="qr-link" />
          <div class="apps-platform">
            <img src="/desktop-pics/app-store.png" class="store-image" @click="openAppStore"/>
            <img src="/desktop-pics/g-play.png" class="store-image" @click="openGooglePlay"/>
           </div>
        </div>
      </div>
    </div>

    <section class="why-we">
      <div class="payment">
        <img src="../../assets/card.svg" alt="QRDelivery Card Icon" />

        <h3>Не забувай про Безконтактну Оплату ❤️</h3>
        <p>З нашим сервісом ти можеш обрати безготівковий розрахунок та уникнути зайвого контакту з кур’єром</p>
      </div>
      <div class="free-deliv">
        <img src="../../assets/rocket.svg" alt="QRDelivery Rocket Icon" />

        <h3>Онлайн меню ❤️</h3>
        <p>Всі заклади міста у твоїй долоні</p>
      </div>
    </section>

    <section class="connect">
      <h4>
        <a href="https://qrwaiter.com.ua">Підключити свій заклад</a>
      </h4>
      <div class="line"></div>
    </section>

    <footer>
      <div class="links">
        <ul>
          <li>
            <a href="https://qrwaiter.com.ua/">Контакти</a>
          </li>
          <li>
            <a href="https://qrwaiter.com.ua/terms.html">Умови використання</a>
          </li>
          <li>
            <a href="https://qrwaiter.com.ua/privacy_policy.html">Конфіденційність</a>
          </li>
        </ul>
      </div>
      <div class="logo">
        <a href="https://qrwaiter.com.ua">
          <img src="/icons/logo.svg" alt="QRWaiter logo" />
        </a>
      </div>
      <div class="copyright">&copy; QRWaiter 2018-2020</div>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    go2Preorder() {
      this.$emit("select", "preorder");
    },
    go2Delivery() {
      this.$emit("select", "delivery");
    },
    go2Order() {
      this.$emit("go2order");
    },
    openAppStore() {
      window.location.href = 'https://apps.apple.com/ua/app/qrdelivery/id1507792224?l=uk';
    },
    openGooglePlay() {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.webqrdelivery.qrdelivery';
    }
  },
  mounted() {
    window.scrollTo(0,0);
  }
};
</script>

<style scoped>
header {
  width: 100%;
  padding: 7vh 0 6vh;
  background: #1d1d1b;
  position: relative;
}

.feature-box img {
  height: 22.5vh;
  width: auto;
}

.feature-box img.special {
  height: 23.4vh;
  width: 36vh;
  margin: -1vh 0 0 -4vh;
}

.features {
  display: flex;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
}

.feature-box {
  flex-basis: 33%;
  text-align: center;
}

.feature-box p {
  color: #a4a3a1;
  padding-top: 2vh;
  font-size: 2.3vh;
}

.feature-box h3 {
  margin: 0;
  padding-top: 4vh;
  color: #ededed;
  font-size: 3.2vh;
}

.wrapper {
  position: relative;
  right: 15vh;
  padding-top: 11vh;
}

.wrapper h4 {
  color: #464646;
  padding-top: 1.7vh;
  font-size: 2.8vh;
}

.available {
  display: flex;
  padding-top: 5.5vh;
}

.qr-link {
  height: 17.7vh;
  width: auto;
}

.apps-platform {
  display: flex;
  flex-flow: column;
  align-self: center;
  padding-left: 6vh;
}

.apps-platform img {
  height: 6vh;
  margin: 0.7vh 0;
  width: auto;
}

.download-box {
  background: #ededed !important;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.download-box h2 {
  font-size: 4.3vh;
  margin: 0;
}

.download-box h1 {
  font-size: 5.5vh;
  margin: 0;
}

img.demo {
  height: 65vh;
  width: auto;

  margin-left: 6vh;
}

h3.blue {
  color: #cbd9e6;
}

h3.yellow {
  color: #ffd2a8;
}

.intro-bg {
  background: url("../../assets/intro.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 29.5vh;
}

.desk-view {
  background: #1d1d1d;
}

header h4 {
  animation: visible 1s;
  color: #ededed;

  text-align: center;
  padding-top: 4.4vh;
  margin-bottom: 0;
  width: 100%;
  font-size: 2.3vh;
}

.order-btn {
  animation: scale 1s;
  background: #e2223b;
  box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.25);
  border-radius: 5vh;
  width: 90%;
  bottom: 18vh;
  border: none;
  outline: none;
  color: #ededed;
  left: 0;
  right: 0;
  position: absolute;
  display: block;
  margin: 0 auto;
  transition: 0.3s;
  padding: 1.6vh 0;
  font-size: 2.2vh;
}

.order-btn:active {
  box-shadow: none;
  transform: translateY(5%);
}

@keyframes scale {
  0% {
    opacity: 0;
    width: 30%;
  }
  100% {
    opacity: 1;
    width: 90%;
  }
}

span.title {
  position: absolute;
  width: 100%;
  text-align: left;
  font-size: 3.5vh;
  color: #1d1d1b;
  line-height: initial;
  padding: 4.5vh 0 0 4.5vh;
}

span.translation {
  position: absolute;
  padding: 4.5vh 4.5vh 0 0;
  color: #a4a3a1;
  font-size: 3.5vh;
  line-height: initial;
  width: 100%;
  text-align: right;
}

header img {
  animation: visible 2s;
  height: 8vh;
  width: 8vh;
  position: relative;
  display: block;
  margin: 0 auto;
}

@keyframes visible {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

img.arrow {
  height: 7vh !important;
  position: absolute;
  right: 4vh;
  top: 50%;
  transform: translateY(-50%);
  width: auto !important;
}

.delivery-box {
  background: #cbd9e6;
}
.preorder-box {
  background: #ffd2a8;
}

.preorder-box,
.delivery-box {
  cursor: pointer;
  width: 100%;
  height: 50vh;
  position: relative;
}

.preorder-box img,
.delivery-box img {
  height: auto;
  width: 72vh;
  position: absolute;
  bottom: 0;
}

.why-we {
  color: #ededed;
  text-align: center;
  display: block;
  background: #1d1d1b;
  margin: 0 auto;
  height: 100vh;
  position: relative;
}

.free-deliv,
.payment {
  padding-top: 10vh;
}

.payment h3,
.free-deliv h3 {
  font-size: 2vw;
  padding-top: 5vh;
}

.payment p,
.free-deliv p {
  font-weight: 400;
  margin: 0 auto;
  color: #a4a3a1;
  width: 44%;
  font-size: 1.5vw;
  padding-top: 2vh;
}

.payment img,
.free-deliv img {
  width: 100%;
  height: 18vh;
}

.connect {
  width: 100%;
  position: relative;
  text-align: center;
  padding: 8vh 0 7vh;

  background: #ededed;
}

.connect h4 {
  cursor: pointer;
  color: #1d1d1b;
  display: block;
  font-weight: 400;
  position: relative;
  z-index: 2;
  font-family: "Chadwick";
  font-size: 2.4vw;
}

h4 a {
  text-decoration: none;
  color: #1d1d1b;
}

.line {
  height: 12px;
  width: 8.5%;
  background: red;
  position: absolute;
  top: 54%;
  z-index: 1;
  float: right;
  right: 35.5%;
}

footer {
  position: relative;
  bottom: 0;
  display: flex;
  background: #1d1d1b;
  padding: 3vh 5vw;
  color: white;
  align-items: flex-end;
  justify-content: space-between;
}

.copyright {
  color: rgba(255, 255, 255, 0.8);
}

li a {
  color: #ededed;
  text-decoration: none;
}

footer img {
  display: block;
  height: auto;
  width: 4vw;
}

footer a {
  text-decoration: none;
}

footer ul {
  margin-bottom: 0;
  padding: 0;
}

.preorder-box {
  display: none;
}

.order-btn {
  display: none !important;
}

@media screen and (max-width: 450px) {
  .order-btn {
    display: block !important;
  }

  .desk-view, .intro-bg {
    display: none !important;
  }

  img.arrow {
    right: 2vh;
  }

  .preorder-box {
    display: block;
  }

  header img {
    height: 17vh;
    width: 17vh;
  }

  .delivery-box img,
  .preorder-box img {
    width: 40vh;
  }

  span.title {
    padding: 2vh 0 0 2vh;
    font-size: 2.5vh;
  }

  span.translation {
    font-size: 2.5vh;
    padding: 2vh 2vh 0 0;
  }

  header {
    height: 100vh;
    padding: 33vh 0;
  }

  .preorder-box,
  .delivery-box {
    width: 100%;
    height: 32vh;
    position: relative;
  }

  .preorder-box,
  .delivery-box {
    width: 100%;
    height: 32vh;
    position: relative;
  }

  header h4 {
    font-size: 2.6vh;
  }

  header {
    background-size: cover !important;
    background-repeat: no-repeat;

    background: url("../../assets/mob-intro.jpg");
  }

  .info {
    top: 14vh;
  }

  h1 {
    font-size: 10vw;
    padding-top: 3vh;
    letter-spacing: 2px;
  }

  .info img {
    width: 50vw;
  }

 

  .free-deliv {
    padding-top: 9.9vh;
  }

  .payment h3,
  .free-deliv h3 {
    font-size: 5vw;
    padding-top: 3vh;
  }

  .connect h4 {
    font-size: 6vw;
  }

  .line {
    height: 8px;
    width: 20%;
    top: 53%;
    right: 15.5%;
  }

  .payment h3 {
    width: 77%;
    margin: 0 auto;
  }

  .payment p,
  .free-deliv p {
    font-size: 4vw;
    width: 77%;
  }

  footer {
    display: block;
    text-align: center;
    padding: 15vh 5vw 2vh;
  }

  footer .logo {
    position: relative;
  }

  ul {
    list-style-type: none;
    padding: 8vh 0 5vh;
  }

  footer img {
    width: auto;
    height: 7vh;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -21vh;
  }

  .links li {
    padding-bottom: 0;
  }

  footer a {
    text-decoration: none;
  }
}

.store-image {
  cursor: pointer;
}
</style>