import BaseAPI from '../base'

function getList(filters) {
    return BaseAPI.sendGet(`marketplace/list`, {
        lat: filters.pos.lat || undefined,
        lon: filters.pos.lon || undefined,
        city: filters.city || undefined,
        modes: filters.modes || undefined,
        price_levels: filters.price_levels || undefined,
        search: filters.search || undefined,
        range: filters.range || undefined
    });
}

function getCities() {
    return BaseAPI.sendGet(`/marketplace/cities`);
}

function getFlavors() {
    return BaseAPI.sendGet(`marketplace/flavors`);
}

export default {
    getList,
    getCities,
    getFlavors
}