<template>
    <div class="location-filter-box">
        <span class="distance">{{distance}} km</span>
        <span class="cafes-amount" v-if="count">
            {{count}} {{ $t('marketplace.chain.count') }}
        </span>
        <input type="range" v-model="distance" class="cafe-filter" min="1" max="100">
    </div>
</template>

<script>
export default {
    data() {
        return {
            distance: 5
        }
    },
    props: {
        count: Number
    },
    watch: {
        distance() {
            this.$emit('input', this.distance);
        }
    }
}
</script>

<style scoped>
.location-filter-box {
    padding-top: 2.5vh;
}

span.distance, span.cafes-amount {
    color: #908F8D;
    font-size: 2.5vh;
    line-height: normal;
    float: left;
}

span.cafes-amount {
    float: right;
}

input[type="range"] {
    width: 100%;
    display: inline-block;
    margin-top: 2vh;
    height: 2vh;
    -webkit-appearance: none;
    outline: none;
    background: #AECCC6;
    border-radius: 2px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 3vh;
  height: 3vh;
  background: #129B81;
border-radius: 4px;
border:none;
  cursor: pointer;
}

</style>